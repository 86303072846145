import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import moment from 'moment-timezone';
import { detectPhoneAgents } from '../../app/dashboard/utils/detectPhoneAgents';

let signedInUser: CognitoUser | undefined = undefined;
let identifiedInAnalytics = false;
let isUserUsingMobile: boolean | undefined = undefined;

async function identify() {
    if (signedInUser == undefined) {
        return;
    }

    await updateAnalyticsUserAttributes();
}

async function setAuthUser() {
    if (signedInUser == undefined) {
        try {
            signedInUser = await Auth.currentAuthenticatedUser();
        } catch (error) {}
    }
}

export const userSignedUpUsingEmail = async () => {
    await setAuthUser();
    await identify();
    track(AnalyticsEvents.SIGNED_UP_USING_EMAIL);
};

export const userSignedIn = async () => {
    await setAuthUser();
    await identify();
    track(AnalyticsEvents.SIGNED_IN);
};

export const updateAnalyticsUserAttributes = async (name?: string, data?: { [key: string]: boolean }[]) => {
    await setAuthUser();
    if (signedInUser == undefined) {
        return;
    }

    let fullName = '';
    let emailVal = '';
    let attributes: any = [];
    try {
        attributes = await Auth.userAttributes(signedInUser);

        const givenNameIndex = attributes.findIndex((attribute: { Name: string }) => attribute.Name == 'given_name');
        const familyNameIndex = attributes.findIndex((attribute: { Name: string }) => attribute.Name == 'family_name');

        if (givenNameIndex >= 0) {
            fullName = attributes[givenNameIndex].Value;
        }
        if (familyNameIndex >= 0) {
            fullName = fullName + ' ' + attributes[familyNameIndex].Value;
        }

        const emailIndex = attributes.findIndex((attribute: { Name: string }) => attribute.Name == 'email');
        if (emailIndex >= 0) {
            emailVal = attributes[emailIndex].Value;
        }
    } catch (error) {
        const attributes = (await Auth.userSession(signedInUser)).getIdToken().payload; // for google users
        fullName = `${attributes.given_name} ${attributes.family_name}`;
        emailVal = attributes.email;
    }

    let cioUserData: {
        cognito_username: string;
        email: string;
        fullName: string;
        [key: string]: any;
    } = {
        cognito_username: signedInUser.getUsername(),
        email: emailVal,
        fullName: fullName,
        name: fullName,
        timezone: moment.tz.guess(),
    };

    if (name && data) {
        Object.keys(data).forEach((key: any) => {
            cioUserData = { ...cioUserData, [`${name} ${key}`]: data[key] };
        });
    }

    attributes.forEach((attribute: { Name: string; Value: any }) => {
        cioUserData[attribute.Name] = attribute.Value;
    });

    await identifyUser(emailVal, cioUserData);
};

export const identifyUser = async (email: string, userData?: any) => {
    const userDataToSet = userData || {};
    if (window.analytics != undefined) {
        try {
            window.analytics.identify(email, userDataToSet);
        } catch (error) {
            console.log('error in identifyUser', error);
        }
        identifiedInAnalytics = true;
    } else {
        console.log('analytics is undefined');
    }
};

export const addCustomAnalyticsUserAttributes = async (name: string, data: any) => {
    await setAuthUser();
    if (signedInUser == undefined) {
        return;
    }
    try {
        await updateAnalyticsUserAttributes(name, data);
    } catch (error) {
        console.error('Error updating user attributes:', error);
    }
};

//list of events
export const AnalyticsEvents = {
    USER_CREATED_IN_DB: 'User Created in DB',
    //project events
    PROJECT_CREATED: 'Project Created',
    PROJECT_TITLE_EDIT: 'Project Title Edit',
    PROJECT_DELETED: 'Project Deleted',
    NEW_PROJECT_BUTTON_PRESSED: 'New Project Button Pressed',
    NEW_PROJECT_PLACEHOLDER_CARD_PRESSED: 'New Project Placholder Card Pressed',

    //product events
    DRAFT_PRODUCT_CREATED: 'New draft product created',
    DRAFT_PRODUCT_CANCELED: 'Draft product canceled',
    PRODUCT_DELETED: 'Product Deleted',
    IMMERSIVE_PRODUCT_ADD_PRODUCT_SCREEN_NEXT: 'Products: Add Immersive Product Screen Next Pressed',
    IMMERSIVE_PRODUCT_ADD_PRODUCT_SCREEN_BACK: 'Products: Add Immersive Product Screen Back Pressed',
    IMMERSIVE_PRODUCT_ADD_PRODUCT_CLOSE: 'Products: Add Immersive Product Screen Close Pressed',
    IMMERSIVE_PRODUCT_QUOTE_EVALUATED: 'Products: Immersive Product Quote Evaluated',

    //editor events
    GENERATE_BUTTON_PRESSED: 'Generate Button Pressed',
    REGENERATE_BUTTON_PRESSED: 'Regenerate Button Pressed',
    CONFIRM_REGENERATE: 'Confirm Regenerate',
    CANCEL_REGENERATE: 'Cancel Regenerate',
    MAKE_MORE_BUTTON_PRESSED: 'Make More Button Pressed',
    STORY_WAS_SELECTED: 'Story Was Selected',
    PRODUCT_IN_STORY_CHANGED: 'Product in Story Changed',
    BLOCK_IN_STORY_CHANGED: 'Block in Story Changed',
    DELETE_STORY_BUTTON_PRESSED: 'Delete Story Button Pressed',
    UNDO_DELETE_STORY_BUTTON_PRESSED: 'Undo Delete Story Button Pressed',
    IMAGE_DOWNLOADED: 'Image Downloaded',
    VIDEO_DOWNLOADED_START: 'Start Video Downloaded',
    VIDEO_DOWNLOADED_END: 'End Video Downloaded',
    PAUSE_BUTTON_PRESSED: 'Pause Button Pressed',
    PLAY_BUTTON_PRESSED: 'Play Button Pressed',
    PRODUCT_IN_USER_BRIEF_CHANGED: 'Product in User Brief Changed',
    BLOCK_IN_USER_BRIEF_CHANGED: 'Block in User Brief Changed',
    PROMPT_CLICKED_OPTIONS_MENU: 'Prompt Clicked Options Menu',
    PROMPT_CLICKED_SURPRISE_ME: 'Prompt Clicked Surprise Me',
    REIMAGINE_WAS_GENERATED: 'Reimagine Was Generated',
    REIMAGINE_WAS_SELECTED_FOR_DISPLAY: 'Reimagine Was Selected For Display',
    REIMAGINE_DISPLAY_WAS_RESET: 'Reimagine Display Was Reset',

    //welcome events
    CONTINUE_PRESSED_IN_WELCOME: 'Continue Pressed in Welcome',
    CONTINUE_PRESSED_IN_SIGN_UP: 'Continue Pressed in Sign Up',
    SIGNED_UP_USING_EMAIL: 'Signed Up Using Email',
    SIGNED_IN: 'Signed In',
    CONTINUE_PRESSED_IN_SIGN_IN: 'Continue Pressed in Sign In',
    CONTINUE_PRESSED_IN_OTP: 'Continue Pressed in OTP',
    RESEND_OTP_CODE: 'Resend OTP Code',
    CONTINUE_PRESSED_IN_UPDATE_DETAILS: 'Continue Pressed in Update Details',
    RESET_PRESSED_IN_SIGN_IN: 'Reset Password Pressed in Sign In',
    GO_BACK_FROM_SIGN_IN_PRESSED: 'Go Back from Sign In Pressed',
    GO_BACK_FROM_SIGN_UP_PRESSED: 'Go Back from Sign In Pressed',

    //auth events
    AUTH_LOGIN_PAGE_VISITED: 'Auth: Login Page Visited',
    AUTH_RESET_PASSWORD_PAGE_VISITED: 'Auth: Reset Password Page Visited',

    //onboarding events
    ONBOARDING_NEXT_PRESSED_WELCOME_SCREEN: 'Onboarding: Next Pressed in Welcome Screen',
    ONBOARDING_NEXT_PRESSED_ROLES_SCREEN: 'Onboarding: Next Pressed in Roles Screen',
    ONBOARDING_NEXT_PRESSED_GOALS_SCREEN: 'Onboarding: Next Pressed in Goals Screen',
    ONBOARDING_NEXT_PRESSED_PRODUCT_SCREEN: 'Onboarding: Next Pressed in Product Screen',
    ONBOARDING_START_PRESSED_START_PROJECT_SCREEN: 'Onboarding: Start Pressed in Start Project Screen',
    ONBOARDING_NEXT_PRESSED_PROJECT_PAGE_SCREEN: 'Onboarding: Next Pressed in Project Page Screen',
    ONBOARDING_DONE_PRESSED_EDITOR_SCREEN: 'Onboarding: Done Pressed in Editor Screen',
    ONBOARDING_NEXT_PRESSED_ADD_PRODUCT_SCREEN: 'Onboarding: Next Pressed in Add Product Screen',
    ONBOARDING_SKIP_PRESSED: 'Onboarding: Skip Pressed',
    ONBOARDING_NEXT_PRESSED_IN_GENERATIONS_PAGE: 'Onboarding: Next Pressed In Generations Page',
    ONBOARDING_SUBSCRIPTION_PAGE_WAS_SEEN: 'Onboarding: Subscription Page Was Seen',
    ONBOARDING_SUBSCRIPTION_PAGE_WAS_CLOSED: 'Onboarding: Subscription Page Was Closed',
    ONBOARDING_START_TRIAL_PRESSED: 'Onboarding: Start Trial Pressed',
    ONBOARDING_TRIAL_SCREEN_CLOSE_BUTTON_PRESSED: 'Onboarding: Trial Screen Close button pressed',

    //express events
    EXPRESS_STORY_DISPLAYED: 'Express: Story Displayed',
    EXPRESS_REIMAGINE_STARTED: 'Express: Reimagine Started',
    EXPRESS_REIMAGINE_FINISHED: 'Express: Reimagine Finished',
    EXPRESS_AI_TOOL_STARTED: 'Express: Express AI Tool Started',
    EXPRESS_AI_TOOL_PRODUCT_SELECTED: 'Express: Express AI Product Selected',
    EXPRESS_AI_IMAGE_DOWNLOADED: 'Express: Express AI Image Downloaded',

    //subscription events
    SUBSCRIPTION_PAGE_VISITED: 'Subscription: Subscription page visited',
    SUBSCRIPTION_CLICKED_SUBSCRIBE: 'Subscription: Clicked Subscribed',
    SUBSCRIPTION_VIEWED_STRIPE_CHECKOUT: 'Subscription: Viewed Stripe Checkout',
    SUBSCRIPTION_CHECKOUT_COMPLETED: 'Subscription: Checkout Completed',
    SUBSCRIPTION_CHECKOUT_CANCELED: 'Subscription: Checkout Canceled',
    SUBSCRIPTION_UPDATED: 'Subscription: Updated',
    SUBSCRIPTION_CANCELED: 'Subscription: Canceled',
    SUBSCRIPTION_RENEWED: 'Subscription: Renewed',
    SUBSCRIPTION_EXPIRED: 'Subscription: Expired',
    SUBSCRIPTION_UPRGADE_ATTEMPT: 'Subscription: Upgrade Attempt',
    SUBSCRIPTION_DOWNGRADE_ATTEMPT: 'Subscription: Downgrade Attempt',
    SUBSCRIPTION_PAYMENT_FAILURE: 'Subscription: Payment Failure',
    SUBSCRIPTION_PAYMENT_SUCCESSFUL: 'Subscription: Payment Successful',
    SUBSCRIPTION_REFUND_ISSUED: 'Subscription: Refund Issued',
    SUBSCRIPTION_RENEWAL_REMINDER: 'Subscription: Renewal Reminder',
    SUBSCRIPTION_PLAN_EXPIRATION_SOON: 'Subscription: Plan Expiration Soon',
    SUBSCRIPTION_PLAN_SWITCH: 'Subscription: Plan Switch',
    PRODUCTS_MODAL_OPEN: 'Products: New Product modal open',
    PRODUCTS_MODAL_NEXT_PRESSED: 'Products: New Product modal clicked Next',
    PRODUCTS_MODAL_X_PRESSED: 'Products: New Product modal clicked X',
    SUBSCRIPTION_PRICING_PAGE_SEEN: 'Subscription: Pricing Page was seen',

    //trial events
    SKIP_TRIAL_BUTTON_PRESSED: 'Skip Trial: Skip Trial Button Pressed',
    SKIP_TRIAL_POPUP_VIEWED: 'Skip Trial: Skip Trial Popup Viewed',
    SKIP_TRIAL_POPUP_DISMISSED: 'Skip Trial: Skip Trial Popup Dismissed',
    SKIP_TRIAL_POPUP_ACCEPTED: 'Skip Trial: Skip Trial Popup button Pressed',

    //feedback
    FEEDBACK_PRESSED: 'Feedback: Feedback was pressed',
    FEEDBACK_SENT: 'Feedback: Feedback was sent',

    //mobile
    MOBILE_PAGE_SEEN: 'Mobile warning page was seen',

    //limit reached events
    LIMIT_REACHED_POPUP_VISITED: 'Limit Reached Popup Visited',

    //add standard product
    PRODUCTS_PAGE_2D_PRODUCT_IMAGE_ADDED: '2D Product: Image added',
    PRODUCTS_PAGE_2D_PRODUCT_IMAGE_UPLOADED: '2D Product: Image uploaded',
    PRODUCTS_PAGE_2D_PRODUCT_NEXT_CLICKED: '2D Product: Next clicked',
    PRODUCTS_PAGE_2D_PRODUCT_CANCEL_CLICKED: '2D Product: Cancel clicked',
    PRODUCTS_PAGE_2D_PRODUCT_DONE_CLICKED: '2D Product: Done clicked',
    PRODUCTS_PAGE_2D_PRODUCT_BACK_CLICKED: '2D Product: Back clicked',
    PRODUCTS_PAGE_2D_PRODUCT_CONTINUE_WITH_ANGLE_VIEW: '2D Product: Continue with angle view',
    PRODUCTS_PAGE_2D_PRODUCT_CANCEL_ANGLE_VIEW: '2D Product: Cancel angle view',

    //project creation by wizard
    PROJECT_CREATION_BY_WIZARD_STARTED: 'Project Creation By Wizard: Started',
    PROJECT_CREATION_BY_WIZARD_MEDIA_VISITED: 'Project Creation By Wizard: Media selection page visited',
    PROJECT_CREATION_BY_WIZARD_MEDIA_SELECTED: 'Project Creation By Wizard: Media selected',
    PROJECT_CREATION_BY_WIZARD_IMMERSIVE_PRODUCT_REQUIRED_VISITED: 'Project Creation By Wizard: Immersive Product Is Required page visited',
    PROJECT_CREATION_BY_WIZARD_IMMERSIVE_PRODUCT_REQUIRED_SAMPLES_SELECTED:
        'Project Creation By Wizard: Immersive Product Is Required, samples selected',
    PROJECT_CREATION_BY_WIZARD_PRODUCT_VISITED: 'Project Creation By Wizard: Product selection page visited',
    PROJECT_CREATION_BY_WIZARD_PRODUCT_SELECTED: 'Project Creation By Wizard: Product selected',
    PROJECT_CREATION_BY_WIZARD_THEME_VISITED: 'Project Creation By Wizard: Theme selection page visited',
    PROJECT_CREATION_BY_WIZARD_THEME_SELECTED: 'Project Creation By Wizard: Theme selected',
    PROJECT_CREATION_BY_WIZARD_THEME_VIEW_SELECTED: 'Project Creation By Wizard: View selected',

    //upgrade screen
    UPGRADE_SCREEN_VISITED: 'Upgrade screen: Visited',

    //user location
    GET_USER_LOCATION_FAILED: 'User Location: Get User Location Failed',

    //home screen
    HOME_SCREEN_FILTER_CLICKED: 'Home screen: Filter was clicked',

    //packs
    PACKS_PACKAGE_VIEWED: 'Packs: Package viewed',
    PACKS_START_CREATING_PRESSED: 'Packs: Start creating was pressed',
    PACKS_QUOTE_SCREEN_X_PRESSED: 'Packs: Quote screen X was pressed',
    PACKS_CHECKOUT_PRESSED: 'Packs: Checkout was pressed',

    //media
    MEDIA_PACK_DOWNLOADED: 'Media: Pack was downloaded',
};

export const AnalyticsProperties = {
    MOBILE_DEVICE: 'Running on mobile device'
}

export const track = async (eventName: string, properties?: any) => {
    if (!identifiedInAnalytics) {
        await setAuthUser();
    }

    if (identifiedInAnalytics) {
        if (window.analytics != undefined) {
            if (isUserUsingMobile === undefined) {
                isUserUsingMobile = detectPhoneAgents();
                track(eventName, properties);
            } else {
                const updatedPropertiesWithMobileInfo = properties ? { ...properties, [AnalyticsProperties.MOBILE_DEVICE]: isUserUsingMobile } : { [AnalyticsProperties.MOBILE_DEVICE]: isUserUsingMobile };
                window.analytics.track(eventName, updatedPropertiesWithMobileInfo);
            }
        } else {
            console.log('analytics is undefined');
        }
    }
};
