/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateProductInput = {
  id?: string | null,
  path: string,
  name: string,
  status: ProductStatus,
  productType?: ProductType | null,
  thumbnailPath?: string | null,
  supportedViewType?: Array< ViewType | null > | null,
  industryType?: string | null,
  productFamily?: string | null,
  size?: ProductSizeInput | null,
  assets?: Array< ProductAssetInput | null > | null,
  dataForInvoice?: ProductDataForInvoiceInput | null,
  username: string,
};

export enum ProductStatus {
  DRAFT = "DRAFT",
  IN_REVIEW = "IN_REVIEW",
  WAITING_FOR_PAYMENT = "WAITING_FOR_PAYMENT",
  PAYMENT_RECEIVED = "PAYMENT_RECEIVED",
  DESIGN_IN_PROGRESS = "DESIGN_IN_PROGRESS",
  AVAILABLE = "AVAILABLE",
  ARCHIVED = "ARCHIVED",
  INACTIVE = "INACTIVE",
}


export enum ProductType {
  SAMPLE = "SAMPLE",
  CUSTOM = "CUSTOM",
  CUSTOM_2D = "CUSTOM_2D",
}


export enum ViewType {
  FRONT = "FRONT",
  TOP = "TOP",
  ANGLE = "ANGLE",
}


export type ProductSizeInput = {
  width?: number | null,
  height?: number | null,
  depth?: number | null,
};

export type ProductAssetInput = {
  url?: string | null,
};

export type ProductDataForInvoiceInput = {
  customProductComplexityRank?: number | null,
  priceId?: string | null,
  immersiveProductId?: string | null,
  priceValue?: number | null,
  priceCurrency?: string | null,
};

export type ModelProductConditionInput = {
  path?: ModelStringInput | null,
  name?: ModelStringInput | null,
  status?: ModelProductStatusInput | null,
  productType?: ModelProductTypeInput | null,
  thumbnailPath?: ModelStringInput | null,
  supportedViewType?: ModelViewTypeListInput | null,
  industryType?: ModelStringInput | null,
  productFamily?: ModelStringInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelProductConditionInput | null > | null,
  or?: Array< ModelProductConditionInput | null > | null,
  not?: ModelProductConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelProductStatusInput = {
  eq?: ProductStatus | null,
  ne?: ProductStatus | null,
};

export type ModelProductTypeInput = {
  eq?: ProductType | null,
  ne?: ProductType | null,
};

export type ModelViewTypeListInput = {
  eq?: Array< ViewType | null > | null,
  ne?: Array< ViewType | null > | null,
  contains?: ViewType | null,
  notContains?: ViewType | null,
};

export type Product = {
  __typename: "Product",
  id: string,
  path: string,
  name: string,
  status: ProductStatus,
  productType?: ProductType | null,
  thumbnailPath?: string | null,
  supportedViewType?: Array< ViewType | null > | null,
  industryType?: string | null,
  productFamily?: string | null,
  size?: ProductSize | null,
  assets?:  Array<ProductAsset | null > | null,
  dataForInvoice?: ProductDataForInvoice | null,
  username: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ProductSize = {
  __typename: "ProductSize",
  width?: number | null,
  height?: number | null,
  depth?: number | null,
};

export type ProductAsset = {
  __typename: "ProductAsset",
  url?: string | null,
};

export type ProductDataForInvoice = {
  __typename: "ProductDataForInvoice",
  customProductComplexityRank?: number | null,
  priceId?: string | null,
  immersiveProductId?: string | null,
  priceValue?: number | null,
  priceCurrency?: string | null,
};

export type UpdateProductInput = {
  id: string,
  path?: string | null,
  name?: string | null,
  status?: ProductStatus | null,
  productType?: ProductType | null,
  thumbnailPath?: string | null,
  supportedViewType?: Array< ViewType | null > | null,
  industryType?: string | null,
  productFamily?: string | null,
  size?: ProductSizeInput | null,
  assets?: Array< ProductAssetInput | null > | null,
  dataForInvoice?: ProductDataForInvoiceInput | null,
  username?: string | null,
};

export type DeleteProductInput = {
  id: string,
};

export type CreateProductSampleInput = {
  id?: string | null,
  path: string,
  name: string,
  thumbnailPath: string,
  size?: ProductSizeInput | null,
};

export type ModelProductSampleConditionInput = {
  path?: ModelStringInput | null,
  name?: ModelStringInput | null,
  thumbnailPath?: ModelStringInput | null,
  and?: Array< ModelProductSampleConditionInput | null > | null,
  or?: Array< ModelProductSampleConditionInput | null > | null,
  not?: ModelProductSampleConditionInput | null,
};

export type ProductSample = {
  __typename: "ProductSample",
  id: string,
  path: string,
  name: string,
  thumbnailPath: string,
  size?: ProductSize | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateProductSampleInput = {
  id: string,
  path?: string | null,
  name?: string | null,
  thumbnailPath?: string | null,
  size?: ProductSizeInput | null,
};

export type DeleteProductSampleInput = {
  id: string,
};

export type CreateSceneInput = {
  id?: string | null,
  path: string,
  name: string,
  brief: BriefInput,
  status: SceneStatus,
  type?: SceneType | null,
  format?: SceneFormat | null,
  sceneDescription?: string | null,
  includeInPromptCatalog?: boolean | null,
  viewsSupported?: Array< ViewType | null > | null,
  thumbnailPath?: string | null,
  isAnimatable?: boolean | null,
  selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
  optionsPerBlock?: Array< Array< string | null > | null > | null,
  themes?: Array< SceneThemeInput | null > | null,
  productSupportedSize?: number | null,
  hasBlurryBG?: boolean | null,
};

export type BriefInput = {
  description?: string | null,
  blocks?: Array< BlockType | null > | null,
};

export enum BlockType {
  MEDIA_MEDIUM = "MEDIA_MEDIUM",
  CAMERA = "CAMERA",
  ANIMATION = "ANIMATION",
  REPLACABLE_OBJECT = "REPLACABLE_OBJECT",
  COLOR = "COLOR",
  LIGHTING = "LIGHTING",
  PRODUCT = "PRODUCT",
  GENRATED_VIEW = "GENRATED_VIEW",
}


export enum SceneStatus {
  LIVE = "LIVE",
  DEV = "DEV",
  ARCHIVED = "ARCHIVED",
}


export enum SceneType {
  GENERAL = "GENERAL",
  ONBOARDING = "ONBOARDING",
  PACKS_ONLY = "PACKS_ONLY",
}


export enum SceneFormat {
  THREE_DIMENSIONAL = "THREE_DIMENSIONAL",
  HYBRID = "HYBRID",
  TWO_DIMENSIONAL = "TWO_DIMENSIONAL",
}


export type SceneThemeInput = {
  main?: string | null,
  sub?: string | null,
};

export type ModelSceneConditionInput = {
  path?: ModelStringInput | null,
  name?: ModelStringInput | null,
  status?: ModelSceneStatusInput | null,
  type?: ModelSceneTypeInput | null,
  format?: ModelSceneFormatInput | null,
  sceneDescription?: ModelStringInput | null,
  includeInPromptCatalog?: ModelBooleanInput | null,
  viewsSupported?: ModelViewTypeListInput | null,
  thumbnailPath?: ModelStringInput | null,
  isAnimatable?: ModelBooleanInput | null,
  selectedValuesOfBlocks?: ModelStringInput | null,
  optionsPerBlock?: ModelStringInput | null,
  productSupportedSize?: ModelFloatInput | null,
  hasBlurryBG?: ModelBooleanInput | null,
  and?: Array< ModelSceneConditionInput | null > | null,
  or?: Array< ModelSceneConditionInput | null > | null,
  not?: ModelSceneConditionInput | null,
};

export type ModelSceneStatusInput = {
  eq?: SceneStatus | null,
  ne?: SceneStatus | null,
};

export type ModelSceneTypeInput = {
  eq?: SceneType | null,
  ne?: SceneType | null,
};

export type ModelSceneFormatInput = {
  eq?: SceneFormat | null,
  ne?: SceneFormat | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type Scene = {
  __typename: "Scene",
  id: string,
  path: string,
  name: string,
  brief: Brief,
  status: SceneStatus,
  type?: SceneType | null,
  format?: SceneFormat | null,
  sceneDescription?: string | null,
  includeInPromptCatalog?: boolean | null,
  viewsSupported?: Array< ViewType | null > | null,
  thumbnailPath?: string | null,
  isAnimatable?: boolean | null,
  selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
  optionsPerBlock?: Array< Array< string | null > | null > | null,
  themes?:  Array<SceneTheme | null > | null,
  productSupportedSize?: number | null,
  hasBlurryBG?: boolean | null,
  bundledScenes?: ModelSceneBundledScenesConnection | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type Brief = {
  __typename: "Brief",
  description?: string | null,
  blocks?: Array< BlockType | null > | null,
};

export type SceneTheme = {
  __typename: "SceneTheme",
  main?: string | null,
  sub?: string | null,
};

export type ModelSceneBundledScenesConnection = {
  __typename: "ModelSceneBundledScenesConnection",
  items:  Array<SceneBundledScenes | null >,
  nextToken?: string | null,
};

export type SceneBundledScenes = {
  __typename: "SceneBundledScenes",
  id: string,
  sceneId: string,
  bundledScenesId: string,
  scene: Scene,
  bundledScenes: BundledScenes,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type BundledScenes = {
  __typename: "BundledScenes",
  id: string,
  name?: string | null,
  description?: string | null,
  thumbnailPath?: string | null,
  scenes?: ModelSceneBundledScenesConnection | null,
  packs?: ModelPackBundledScenesConnection | null,
  mediaType?: MediaType | null,
  status?: BundledScenesStatus | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type ModelPackBundledScenesConnection = {
  __typename: "ModelPackBundledScenesConnection",
  items:  Array<PackBundledScenes | null >,
  nextToken?: string | null,
};

export type PackBundledScenes = {
  __typename: "PackBundledScenes",
  id: string,
  bundledScenesId: string,
  packId: string,
  bundledScenes: BundledScenes,
  pack: Pack,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type Pack = {
  __typename: "Pack",
  id: string,
  name?: string | null,
  description?: string | null,
  thumbnailPath?: string | null,
  level?: number | null,
  bundledScenes?: ModelPackBundledScenesConnection | null,
  tags?: Array< PackTag | null > | null,
  status?: PackStatus | null,
  order?: number | null,
  exampleImages?: Array< string | null > | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export enum PackTag {
  RECOMMENDED = "RECOMMENDED",
  AMAZON = "AMAZON",
  SHOPIFY = "SHOPIFY",
  SALES_BOOSTERS = "SALES_BOOSTERS",
  SEASONAL = "SEASONAL",
  ETSY = "ETSY",
}


export enum PackStatus {
  LIVE = "LIVE",
  DEV = "DEV",
  ARCHIVED = "ARCHIVED",
}


export enum MediaType {
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
}


export enum BundledScenesStatus {
  LIVE = "LIVE",
  DEV = "DEV",
  ARCHIVED = "ARCHIVED",
}


export type UpdateSceneInput = {
  id: string,
  path?: string | null,
  name?: string | null,
  brief?: BriefInput | null,
  status?: SceneStatus | null,
  type?: SceneType | null,
  format?: SceneFormat | null,
  sceneDescription?: string | null,
  includeInPromptCatalog?: boolean | null,
  viewsSupported?: Array< ViewType | null > | null,
  thumbnailPath?: string | null,
  isAnimatable?: boolean | null,
  selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
  optionsPerBlock?: Array< Array< string | null > | null > | null,
  themes?: Array< SceneThemeInput | null > | null,
  productSupportedSize?: number | null,
  hasBlurryBG?: boolean | null,
};

export type DeleteSceneInput = {
  id: string,
};

export type CreateBundledScenesInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  thumbnailPath?: string | null,
  mediaType?: MediaType | null,
  status?: BundledScenesStatus | null,
};

export type ModelBundledScenesConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  thumbnailPath?: ModelStringInput | null,
  mediaType?: ModelMediaTypeInput | null,
  status?: ModelBundledScenesStatusInput | null,
  and?: Array< ModelBundledScenesConditionInput | null > | null,
  or?: Array< ModelBundledScenesConditionInput | null > | null,
  not?: ModelBundledScenesConditionInput | null,
};

export type ModelMediaTypeInput = {
  eq?: MediaType | null,
  ne?: MediaType | null,
};

export type ModelBundledScenesStatusInput = {
  eq?: BundledScenesStatus | null,
  ne?: BundledScenesStatus | null,
};

export type UpdateBundledScenesInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  thumbnailPath?: string | null,
  mediaType?: MediaType | null,
  status?: BundledScenesStatus | null,
};

export type DeleteBundledScenesInput = {
  id: string,
};

export type CreatePackInput = {
  id?: string | null,
  name?: string | null,
  description?: string | null,
  thumbnailPath?: string | null,
  level?: number | null,
  tags?: Array< PackTag | null > | null,
  status?: PackStatus | null,
  order?: number | null,
  exampleImages?: Array< string | null > | null,
};

export type ModelPackConditionInput = {
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  thumbnailPath?: ModelStringInput | null,
  level?: ModelIntInput | null,
  tags?: ModelPackTagListInput | null,
  status?: ModelPackStatusInput | null,
  order?: ModelIntInput | null,
  exampleImages?: ModelStringInput | null,
  and?: Array< ModelPackConditionInput | null > | null,
  or?: Array< ModelPackConditionInput | null > | null,
  not?: ModelPackConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelPackTagListInput = {
  eq?: Array< PackTag | null > | null,
  ne?: Array< PackTag | null > | null,
  contains?: PackTag | null,
  notContains?: PackTag | null,
};

export type ModelPackStatusInput = {
  eq?: PackStatus | null,
  ne?: PackStatus | null,
};

export type UpdatePackInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  thumbnailPath?: string | null,
  level?: number | null,
  tags?: Array< PackTag | null > | null,
  status?: PackStatus | null,
  order?: number | null,
  exampleImages?: Array< string | null > | null,
};

export type DeletePackInput = {
  id: string,
};

export type CreateGeneratedPackInput = {
  id?: string | null,
  status?: GeneratedPackStatus | null,
  username: string,
  generatedPackPackId?: string | null,
  generatedPackProductId?: string | null,
};

export enum GeneratedPackStatus {
  DRAFT = "DRAFT",
  PROCESSING = "PROCESSING",
  READY = "READY",
}


export type ModelGeneratedPackConditionInput = {
  status?: ModelGeneratedPackStatusInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelGeneratedPackConditionInput | null > | null,
  or?: Array< ModelGeneratedPackConditionInput | null > | null,
  not?: ModelGeneratedPackConditionInput | null,
  generatedPackPackId?: ModelIDInput | null,
  generatedPackProductId?: ModelIDInput | null,
};

export type ModelGeneratedPackStatusInput = {
  eq?: GeneratedPackStatus | null,
  ne?: GeneratedPackStatus | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type GeneratedPack = {
  __typename: "GeneratedPack",
  id: string,
  pack?: Pack | null,
  product?: Product | null,
  status?: GeneratedPackStatus | null,
  username: string,
  createdAt: string,
  updatedAt: string,
  generatedPackPackId?: string | null,
  generatedPackProductId?: string | null,
  owner?: string | null,
};

export type UpdateGeneratedPackInput = {
  id: string,
  status?: GeneratedPackStatus | null,
  username?: string | null,
  generatedPackPackId?: string | null,
  generatedPackProductId?: string | null,
};

export type DeleteGeneratedPackInput = {
  id: string,
};

export type CreateTrackedProgressItemInput = {
  id?: string | null,
  progress: number,
};

export type ModelTrackedProgressItemConditionInput = {
  progress?: ModelIntInput | null,
  and?: Array< ModelTrackedProgressItemConditionInput | null > | null,
  or?: Array< ModelTrackedProgressItemConditionInput | null > | null,
  not?: ModelTrackedProgressItemConditionInput | null,
};

export type TrackedProgressItem = {
  __typename: "TrackedProgressItem",
  id: string,
  progress: number,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateTrackedProgressItemInput = {
  id: string,
  progress?: number | null,
};

export type DeleteTrackedProgressItemInput = {
  id: string,
};

export type CreateStoryInput = {
  id?: string | null,
  sceneId: string,
  scenePath: string,
  sceneName: string,
  sceneBrief: BriefInput,
  sceneStatus: SceneStatus,
  sceneType?: SceneType | null,
  format?: SceneFormat | null,
  sceneHasBlurryBG?: boolean | null,
  selectedValuesOfBlocks?: Array< string | null > | null,
  optionsPerBlock?: Array< Array< string | null > | null > | null,
  status?: StoryStatus | null,
  similarity?: number | null,
  isGeneratedFromHistory?: boolean | null,
  reimaginesData?: Array< ReimagineDataInput | null > | null,
  owner?: string | null,
  projectStoriesId?: string | null,
};

export enum StoryStatus {
  INACTIVE = "INACTIVE",
  DRAFT = "DRAFT",
  PUBLISHED = "PUBLISHED",
}


export type ReimagineDataInput = {
  s3key?: string | null,
  type?: ReimagineType | null,
  selectedValuesOfBlocks?: Array< string | null > | null,
  reimagineInfo?: ReimagineInfoInput | null,
};

export enum ReimagineType {
  BASIC = "BASIC",
}


export type ReimagineInfoInput = {
  option?: string | null,
  category?: string | null,
  preset?: string | null,
};

export type ModelStoryConditionInput = {
  sceneId?: ModelIDInput | null,
  scenePath?: ModelStringInput | null,
  sceneName?: ModelStringInput | null,
  sceneStatus?: ModelSceneStatusInput | null,
  sceneType?: ModelSceneTypeInput | null,
  format?: ModelSceneFormatInput | null,
  sceneHasBlurryBG?: ModelBooleanInput | null,
  selectedValuesOfBlocks?: ModelStringInput | null,
  optionsPerBlock?: ModelStringInput | null,
  status?: ModelStoryStatusInput | null,
  similarity?: ModelIntInput | null,
  isGeneratedFromHistory?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelStoryConditionInput | null > | null,
  or?: Array< ModelStoryConditionInput | null > | null,
  not?: ModelStoryConditionInput | null,
  projectStoriesId?: ModelIDInput | null,
};

export type ModelStoryStatusInput = {
  eq?: StoryStatus | null,
  ne?: StoryStatus | null,
};

export type Story = {
  __typename: "Story",
  id: string,
  sceneId: string,
  scenePath: string,
  sceneName: string,
  sceneBrief: Brief,
  sceneStatus: SceneStatus,
  sceneType?: SceneType | null,
  format?: SceneFormat | null,
  sceneHasBlurryBG?: boolean | null,
  selectedValuesOfBlocks?: Array< string | null > | null,
  optionsPerBlock?: Array< Array< string | null > | null > | null,
  status?: StoryStatus | null,
  similarity?: number | null,
  isGeneratedFromHistory?: boolean | null,
  reimaginesData?:  Array<ReimagineData | null > | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  projectStoriesId?: string | null,
};

export type ReimagineData = {
  __typename: "ReimagineData",
  s3key?: string | null,
  type?: ReimagineType | null,
  selectedValuesOfBlocks?: Array< string | null > | null,
  reimagineInfo?: ReimagineInfo | null,
};

export type ReimagineInfo = {
  __typename: "ReimagineInfo",
  option?: string | null,
  category?: string | null,
  preset?: string | null,
};

export type UpdateStoryInput = {
  id: string,
  sceneId?: string | null,
  scenePath?: string | null,
  sceneName?: string | null,
  sceneBrief?: BriefInput | null,
  sceneStatus?: SceneStatus | null,
  sceneType?: SceneType | null,
  format?: SceneFormat | null,
  sceneHasBlurryBG?: boolean | null,
  selectedValuesOfBlocks?: Array< string | null > | null,
  optionsPerBlock?: Array< Array< string | null > | null > | null,
  status?: StoryStatus | null,
  similarity?: number | null,
  isGeneratedFromHistory?: boolean | null,
  reimaginesData?: Array< ReimagineDataInput | null > | null,
  owner?: string | null,
  projectStoriesId?: string | null,
};

export type DeleteStoryInput = {
  id: string,
};

export type CreateProjectInput = {
  id?: string | null,
  name?: string | null,
  userBrief?: BriefInput | null,
  selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
  type: string,
  updatedAt?: string | null,
  status?: ProjectStatus | null,
  wizardDialogData?: WizardDialogDataInput | null,
  imagesContstructingThumbnailIds?: Array< string | null > | null,
  username: string,
  folderProjectsId?: string | null,
};

export enum ProjectStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}


export type WizardDialogDataInput = {
  step_1?: string | null,
  step_2?: string | null,
};

export type ModelProjectConditionInput = {
  name?: ModelStringInput | null,
  selectedValuesOfUserBriefBlocks?: ModelStringInput | null,
  type?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  status?: ModelProjectStatusInput | null,
  imagesContstructingThumbnailIds?: ModelStringInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelProjectConditionInput | null > | null,
  or?: Array< ModelProjectConditionInput | null > | null,
  not?: ModelProjectConditionInput | null,
  folderProjectsId?: ModelIDInput | null,
};

export type ModelProjectStatusInput = {
  eq?: ProjectStatus | null,
  ne?: ProjectStatus | null,
};

export type Project = {
  __typename: "Project",
  id: string,
  name?: string | null,
  userBrief?: Brief | null,
  selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
  stories?: ModelStoryConnection | null,
  containingFolder?: Folder | null,
  type: string,
  updatedAt: string,
  status?: ProjectStatus | null,
  wizardDialogData?: WizardDialogData | null,
  imagesContstructingThumbnailIds?: Array< string | null > | null,
  username: string,
  createdAt: string,
  folderProjectsId?: string | null,
  owner?: string | null,
};

export type ModelStoryConnection = {
  __typename: "ModelStoryConnection",
  items:  Array<Story | null >,
  nextToken?: string | null,
};

export type Folder = {
  __typename: "Folder",
  id: string,
  name: string,
  projects?: ModelProjectConnection | null,
  childFolders?: ModelFolderConnection | null,
  parent?: Folder | null,
  owner?: string | null,
  createdAt: string,
  updatedAt: string,
  folderChildFoldersId?: string | null,
  folderParentId?: string | null,
};

export type ModelProjectConnection = {
  __typename: "ModelProjectConnection",
  items:  Array<Project | null >,
  nextToken?: string | null,
};

export type ModelFolderConnection = {
  __typename: "ModelFolderConnection",
  items:  Array<Folder | null >,
  nextToken?: string | null,
};

export type WizardDialogData = {
  __typename: "WizardDialogData",
  step_1?: string | null,
  step_2?: string | null,
};

export type UpdateProjectInput = {
  id: string,
  name?: string | null,
  userBrief?: BriefInput | null,
  selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
  type?: string | null,
  updatedAt?: string | null,
  status?: ProjectStatus | null,
  wizardDialogData?: WizardDialogDataInput | null,
  imagesContstructingThumbnailIds?: Array< string | null > | null,
  username?: string | null,
  folderProjectsId?: string | null,
};

export type DeleteProjectInput = {
  id: string,
};

export type CreateFolderInput = {
  id?: string | null,
  name: string,
  owner?: string | null,
  folderChildFoldersId?: string | null,
  folderParentId?: string | null,
};

export type ModelFolderConditionInput = {
  name?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelFolderConditionInput | null > | null,
  or?: Array< ModelFolderConditionInput | null > | null,
  not?: ModelFolderConditionInput | null,
  folderChildFoldersId?: ModelIDInput | null,
  folderParentId?: ModelIDInput | null,
};

export type UpdateFolderInput = {
  id: string,
  name?: string | null,
  owner?: string | null,
  folderChildFoldersId?: string | null,
  folderParentId?: string | null,
};

export type DeleteFolderInput = {
  id: string,
};

export type CreateSpaceInput = {
  id?: string | null,
  name: string,
  username: string,
  spaceRootFolderId?: string | null,
};

export type ModelSpaceConditionInput = {
  name?: ModelStringInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelSpaceConditionInput | null > | null,
  or?: Array< ModelSpaceConditionInput | null > | null,
  not?: ModelSpaceConditionInput | null,
  spaceRootFolderId?: ModelIDInput | null,
};

export type Space = {
  __typename: "Space",
  id: string,
  name: string,
  rootFolder?: Folder | null,
  username: string,
  createdAt: string,
  updatedAt: string,
  spaceRootFolderId?: string | null,
  owner?: string | null,
};

export type UpdateSpaceInput = {
  id: string,
  name?: string | null,
  username?: string | null,
  spaceRootFolderId?: string | null,
};

export type DeleteSpaceInput = {
  id: string,
};

export type CreateActiveSpaceInput = {
  owner: string,
  activeSpaceSpaceId?: string | null,
};

export type ModelActiveSpaceConditionInput = {
  and?: Array< ModelActiveSpaceConditionInput | null > | null,
  or?: Array< ModelActiveSpaceConditionInput | null > | null,
  not?: ModelActiveSpaceConditionInput | null,
  activeSpaceSpaceId?: ModelIDInput | null,
};

export type ActiveSpace = {
  __typename: "ActiveSpace",
  space?: Space | null,
  owner: string,
  createdAt: string,
  updatedAt: string,
  activeSpaceSpaceId?: string | null,
};

export type UpdateActiveSpaceInput = {
  owner: string,
  activeSpaceSpaceId?: string | null,
};

export type DeleteActiveSpaceInput = {
  owner: string,
};

export type CreateActiveProductInput = {
  owner: string,
  activeProductProductId?: string | null,
};

export type ModelActiveProductConditionInput = {
  and?: Array< ModelActiveProductConditionInput | null > | null,
  or?: Array< ModelActiveProductConditionInput | null > | null,
  not?: ModelActiveProductConditionInput | null,
  activeProductProductId?: ModelIDInput | null,
};

export type ActiveProduct = {
  __typename: "ActiveProduct",
  product?: Product | null,
  owner: string,
  createdAt: string,
  updatedAt: string,
  activeProductProductId?: string | null,
};

export type UpdateActiveProductInput = {
  owner: string,
  activeProductProductId?: string | null,
};

export type DeleteActiveProductInput = {
  owner: string,
};

export type CreateUserInput = {
  onboarding: OnboardingTypeInput,
  roles?: Array< UserAttributeInput | null > | null,
  goals?: Array< UserAttributeInput | null > | null,
  stripeId?: string | null,
  limitation?: LimitationTypeInput | null,
  seenSceneIds?: Array< string | null > | null,
  isSubscriptionCanceled?: boolean | null,
  isReimagineClicked?: boolean | null,
  trialSubscriptionStatus?: TrialSubscriptionStatus | null,
  location?: LocationInput | null,
  paymentMethodStatus?: PaymentMethodStatus | null,
  seenGeneratedPackIds?: Array< string | null > | null,
  owner: string,
};

export type OnboardingTypeInput = {
  formSteps?: Array< FormStepsInput | null > | null,
  uiSteps?: Array< UIStepsInput | null > | null,
};

export type FormStepsInput = {
  id?: number | null,
  data?: string | null,
  isSubmited?: boolean | null,
};

export type UIStepsInput = {
  id?: number | null,
  completedStepTypes?: Array< UIStepType | null > | null,
  isSubmited?: boolean | null,
};

export enum UIStepType {
  WIZARD = "WIZARD",
  PROMPT = "PROMPT",
}


export type UserAttributeInput = {
  id?: number | null,
  value?: string | null,
};

export type LimitationTypeInput = {
  generatedStoriesInLimitPeriod?: number | null,
  createdProjectsInLimitPeriod?: number | null,
  createdCustomProductsInLimitPeriod?: number | null,
  createdStandardProductsInLimitPeriod?: number | null,
  generatedReimagines?: number | null,
  downloadedImages?: number | null,
  downloadedVideos?: number | null,
  generatedPacks?: number | null,
  subscriptionStartDate?: string | null,
  limitResetDate?: string | null,
  projectLimit?: number | null,
  storiesLimit?: number | null,
  storiesLimitPeriod?: string | null,
  customProducts?: number | null,
  standardProducts?: number | null,
  images?: number | null,
  videos?: number | null,
  packsLimit?: number | null,
};

export enum TrialSubscriptionStatus {
  NOT_STARTED = "NOT_STARTED",
  IN_PROGRESS = "IN_PROGRESS",
  EXPIRED = "EXPIRED",
}


export type LocationInput = {
  country?: string | null,
  countryName?: string | null,
  countryCode?: string | null,
  countryCodeISO3?: string | null,
  currency?: string | null,
  currencyName?: string | null,
  languages?: string | null,
};

export enum PaymentMethodStatus {
  VALID = "VALID",
  NOT_VALID = "NOT_VALID",
}


export type ModelUserConditionInput = {
  stripeId?: ModelStringInput | null,
  seenSceneIds?: ModelStringInput | null,
  isSubscriptionCanceled?: ModelBooleanInput | null,
  isReimagineClicked?: ModelBooleanInput | null,
  trialSubscriptionStatus?: ModelTrialSubscriptionStatusInput | null,
  paymentMethodStatus?: ModelPaymentMethodStatusInput | null,
  seenGeneratedPackIds?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelTrialSubscriptionStatusInput = {
  eq?: TrialSubscriptionStatus | null,
  ne?: TrialSubscriptionStatus | null,
};

export type ModelPaymentMethodStatusInput = {
  eq?: PaymentMethodStatus | null,
  ne?: PaymentMethodStatus | null,
};

export type User = {
  __typename: "User",
  onboarding: OnboardingType,
  roles?:  Array<UserAttribute | null > | null,
  goals?:  Array<UserAttribute | null > | null,
  stripeId?: string | null,
  limitation?: LimitationType | null,
  seenSceneIds?: Array< string | null > | null,
  isSubscriptionCanceled?: boolean | null,
  isReimagineClicked?: boolean | null,
  trialSubscriptionStatus?: TrialSubscriptionStatus | null,
  location?: Location | null,
  paymentMethodStatus?: PaymentMethodStatus | null,
  seenGeneratedPackIds?: Array< string | null > | null,
  owner: string,
  createdAt: string,
  updatedAt: string,
};

export type OnboardingType = {
  __typename: "OnboardingType",
  formSteps?:  Array<FormSteps | null > | null,
  uiSteps?:  Array<UISteps | null > | null,
};

export type FormSteps = {
  __typename: "FormSteps",
  id?: number | null,
  data?: string | null,
  isSubmited?: boolean | null,
};

export type UISteps = {
  __typename: "UISteps",
  id?: number | null,
  completedStepTypes?: Array< UIStepType | null > | null,
  isSubmited?: boolean | null,
};

export type UserAttribute = {
  __typename: "UserAttribute",
  id?: number | null,
  value?: string | null,
};

export type LimitationType = {
  __typename: "LimitationType",
  generatedStoriesInLimitPeriod?: number | null,
  createdProjectsInLimitPeriod?: number | null,
  createdCustomProductsInLimitPeriod?: number | null,
  createdStandardProductsInLimitPeriod?: number | null,
  generatedReimagines?: number | null,
  downloadedImages?: number | null,
  downloadedVideos?: number | null,
  generatedPacks?: number | null,
  subscriptionStartDate?: string | null,
  limitResetDate?: string | null,
  projectLimit?: number | null,
  storiesLimit?: number | null,
  storiesLimitPeriod?: string | null,
  customProducts?: number | null,
  standardProducts?: number | null,
  images?: number | null,
  videos?: number | null,
  packsLimit?: number | null,
};

export type Location = {
  __typename: "Location",
  country?: string | null,
  countryName?: string | null,
  countryCode?: string | null,
  countryCodeISO3?: string | null,
  currency?: string | null,
  currencyName?: string | null,
  languages?: string | null,
};

export type UpdateUserInput = {
  onboarding?: OnboardingTypeInput | null,
  roles?: Array< UserAttributeInput | null > | null,
  goals?: Array< UserAttributeInput | null > | null,
  stripeId?: string | null,
  limitation?: LimitationTypeInput | null,
  seenSceneIds?: Array< string | null > | null,
  isSubscriptionCanceled?: boolean | null,
  isReimagineClicked?: boolean | null,
  trialSubscriptionStatus?: TrialSubscriptionStatus | null,
  location?: LocationInput | null,
  paymentMethodStatus?: PaymentMethodStatus | null,
  seenGeneratedPackIds?: Array< string | null > | null,
  owner: string,
};

export type DeleteUserInput = {
  owner: string,
};

export type CreatePromptCatalogInput = {
  id?: string | null,
  colors?: Array< CategoryInput | null > | null,
  elements?: Array< CategoryInput | null > | null,
  descriptions?: Array< CategoryInput | null > | null,
};

export type CategoryInput = {
  name: string,
  values?: Array< string | null > | null,
  subCategories?: Array< CategoryInput | null > | null,
};

export type ModelPromptCatalogConditionInput = {
  and?: Array< ModelPromptCatalogConditionInput | null > | null,
  or?: Array< ModelPromptCatalogConditionInput | null > | null,
  not?: ModelPromptCatalogConditionInput | null,
};

export type PromptCatalog = {
  __typename: "PromptCatalog",
  id: string,
  colors?:  Array<Category | null > | null,
  elements?:  Array<Category | null > | null,
  descriptions?:  Array<Category | null > | null,
  createdAt: string,
  updatedAt: string,
};

export type Category = {
  __typename: "Category",
  name: string,
  values?: Array< string | null > | null,
  subCategories?:  Array<Category | null > | null,
};

export type UpdatePromptCatalogInput = {
  id: string,
  colors?: Array< CategoryInput | null > | null,
  elements?: Array< CategoryInput | null > | null,
  descriptions?: Array< CategoryInput | null > | null,
};

export type DeletePromptCatalogInput = {
  id: string,
};

export type CreatePromptHistoryInput = {
  description?: string | null,
  score?: Array< number | null > | null,
  username: string,
  id?: string | null,
};

export type ModelPromptHistoryConditionInput = {
  description?: ModelStringInput | null,
  score?: ModelIntInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelPromptHistoryConditionInput | null > | null,
  or?: Array< ModelPromptHistoryConditionInput | null > | null,
  not?: ModelPromptHistoryConditionInput | null,
};

export type PromptHistory = {
  __typename: "PromptHistory",
  description?: string | null,
  score?: Array< number | null > | null,
  username: string,
  id: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdatePromptHistoryInput = {
  description?: string | null,
  score?: Array< number | null > | null,
  username?: string | null,
  id: string,
};

export type DeletePromptHistoryInput = {
  id: string,
};

export type CreateServiceInput = {
  id?: string | null,
  name: string,
  poorAvailabilityDetectedTime?: string | null,
};

export type ModelServiceConditionInput = {
  name?: ModelStringInput | null,
  poorAvailabilityDetectedTime?: ModelStringInput | null,
  and?: Array< ModelServiceConditionInput | null > | null,
  or?: Array< ModelServiceConditionInput | null > | null,
  not?: ModelServiceConditionInput | null,
};

export type Service = {
  __typename: "Service",
  id: string,
  name: string,
  poorAvailabilityDetectedTime?: string | null,
  createdAt: string,
  updatedAt: string,
};

export type UpdateServiceInput = {
  id: string,
  name?: string | null,
  poorAvailabilityDetectedTime?: string | null,
};

export type DeleteServiceInput = {
  id: string,
};

export type CreateSceneBundledScenesInput = {
  id?: string | null,
  sceneId: string,
  bundledScenesId: string,
};

export type ModelSceneBundledScenesConditionInput = {
  sceneId?: ModelIDInput | null,
  bundledScenesId?: ModelIDInput | null,
  and?: Array< ModelSceneBundledScenesConditionInput | null > | null,
  or?: Array< ModelSceneBundledScenesConditionInput | null > | null,
  not?: ModelSceneBundledScenesConditionInput | null,
};

export type UpdateSceneBundledScenesInput = {
  id: string,
  sceneId?: string | null,
  bundledScenesId?: string | null,
};

export type DeleteSceneBundledScenesInput = {
  id: string,
};

export type CreatePackBundledScenesInput = {
  id?: string | null,
  bundledScenesId: string,
  packId: string,
};

export type ModelPackBundledScenesConditionInput = {
  bundledScenesId?: ModelIDInput | null,
  packId?: ModelIDInput | null,
  and?: Array< ModelPackBundledScenesConditionInput | null > | null,
  or?: Array< ModelPackBundledScenesConditionInput | null > | null,
  not?: ModelPackBundledScenesConditionInput | null,
};

export type UpdatePackBundledScenesInput = {
  id: string,
  bundledScenesId?: string | null,
  packId?: string | null,
};

export type DeletePackBundledScenesInput = {
  id: string,
};

export type ModelProductFilterInput = {
  id?: ModelIDInput | null,
  path?: ModelStringInput | null,
  name?: ModelStringInput | null,
  status?: ModelProductStatusInput | null,
  productType?: ModelProductTypeInput | null,
  thumbnailPath?: ModelStringInput | null,
  supportedViewType?: ModelViewTypeListInput | null,
  industryType?: ModelStringInput | null,
  productFamily?: ModelStringInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelProductFilterInput | null > | null,
  or?: Array< ModelProductFilterInput | null > | null,
  not?: ModelProductFilterInput | null,
};

export type ModelProductConnection = {
  __typename: "ModelProductConnection",
  items:  Array<Product | null >,
  nextToken?: string | null,
};

export type ModelProductSampleFilterInput = {
  id?: ModelIDInput | null,
  path?: ModelStringInput | null,
  name?: ModelStringInput | null,
  thumbnailPath?: ModelStringInput | null,
  and?: Array< ModelProductSampleFilterInput | null > | null,
  or?: Array< ModelProductSampleFilterInput | null > | null,
  not?: ModelProductSampleFilterInput | null,
};

export type ModelProductSampleConnection = {
  __typename: "ModelProductSampleConnection",
  items:  Array<ProductSample | null >,
  nextToken?: string | null,
};

export type ModelSceneFilterInput = {
  id?: ModelIDInput | null,
  path?: ModelStringInput | null,
  name?: ModelStringInput | null,
  status?: ModelSceneStatusInput | null,
  type?: ModelSceneTypeInput | null,
  format?: ModelSceneFormatInput | null,
  sceneDescription?: ModelStringInput | null,
  includeInPromptCatalog?: ModelBooleanInput | null,
  viewsSupported?: ModelViewTypeListInput | null,
  thumbnailPath?: ModelStringInput | null,
  isAnimatable?: ModelBooleanInput | null,
  selectedValuesOfBlocks?: ModelStringInput | null,
  optionsPerBlock?: ModelStringInput | null,
  productSupportedSize?: ModelFloatInput | null,
  hasBlurryBG?: ModelBooleanInput | null,
  and?: Array< ModelSceneFilterInput | null > | null,
  or?: Array< ModelSceneFilterInput | null > | null,
  not?: ModelSceneFilterInput | null,
};

export type ModelSceneConnection = {
  __typename: "ModelSceneConnection",
  items:  Array<Scene | null >,
  nextToken?: string | null,
};

export type ModelBundledScenesFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  thumbnailPath?: ModelStringInput | null,
  mediaType?: ModelMediaTypeInput | null,
  status?: ModelBundledScenesStatusInput | null,
  and?: Array< ModelBundledScenesFilterInput | null > | null,
  or?: Array< ModelBundledScenesFilterInput | null > | null,
  not?: ModelBundledScenesFilterInput | null,
};

export type ModelBundledScenesConnection = {
  __typename: "ModelBundledScenesConnection",
  items:  Array<BundledScenes | null >,
  nextToken?: string | null,
};

export type ModelPackFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  description?: ModelStringInput | null,
  thumbnailPath?: ModelStringInput | null,
  level?: ModelIntInput | null,
  tags?: ModelPackTagListInput | null,
  status?: ModelPackStatusInput | null,
  order?: ModelIntInput | null,
  exampleImages?: ModelStringInput | null,
  and?: Array< ModelPackFilterInput | null > | null,
  or?: Array< ModelPackFilterInput | null > | null,
  not?: ModelPackFilterInput | null,
};

export type ModelPackConnection = {
  __typename: "ModelPackConnection",
  items:  Array<Pack | null >,
  nextToken?: string | null,
};

export type ModelGeneratedPackFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelGeneratedPackStatusInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelGeneratedPackFilterInput | null > | null,
  or?: Array< ModelGeneratedPackFilterInput | null > | null,
  not?: ModelGeneratedPackFilterInput | null,
  generatedPackPackId?: ModelIDInput | null,
  generatedPackProductId?: ModelIDInput | null,
};

export type ModelGeneratedPackConnection = {
  __typename: "ModelGeneratedPackConnection",
  items:  Array<GeneratedPack | null >,
  nextToken?: string | null,
};

export type ModelTrackedProgressItemFilterInput = {
  id?: ModelIDInput | null,
  progress?: ModelIntInput | null,
  and?: Array< ModelTrackedProgressItemFilterInput | null > | null,
  or?: Array< ModelTrackedProgressItemFilterInput | null > | null,
  not?: ModelTrackedProgressItemFilterInput | null,
};

export type ModelTrackedProgressItemConnection = {
  __typename: "ModelTrackedProgressItemConnection",
  items:  Array<TrackedProgressItem | null >,
  nextToken?: string | null,
};

export type ModelStoryFilterInput = {
  id?: ModelIDInput | null,
  sceneId?: ModelIDInput | null,
  scenePath?: ModelStringInput | null,
  sceneName?: ModelStringInput | null,
  sceneStatus?: ModelSceneStatusInput | null,
  sceneType?: ModelSceneTypeInput | null,
  format?: ModelSceneFormatInput | null,
  sceneHasBlurryBG?: ModelBooleanInput | null,
  selectedValuesOfBlocks?: ModelStringInput | null,
  optionsPerBlock?: ModelStringInput | null,
  status?: ModelStoryStatusInput | null,
  similarity?: ModelIntInput | null,
  isGeneratedFromHistory?: ModelBooleanInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelStoryFilterInput | null > | null,
  or?: Array< ModelStoryFilterInput | null > | null,
  not?: ModelStoryFilterInput | null,
  projectStoriesId?: ModelIDInput | null,
};

export type ModelProjectFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  selectedValuesOfUserBriefBlocks?: ModelStringInput | null,
  type?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  status?: ModelProjectStatusInput | null,
  imagesContstructingThumbnailIds?: ModelStringInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelProjectFilterInput | null > | null,
  or?: Array< ModelProjectFilterInput | null > | null,
  not?: ModelProjectFilterInput | null,
  folderProjectsId?: ModelIDInput | null,
};

export type ModelFolderFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelFolderFilterInput | null > | null,
  or?: Array< ModelFolderFilterInput | null > | null,
  not?: ModelFolderFilterInput | null,
  folderChildFoldersId?: ModelIDInput | null,
  folderParentId?: ModelIDInput | null,
};

export type ModelSpaceFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelSpaceFilterInput | null > | null,
  or?: Array< ModelSpaceFilterInput | null > | null,
  not?: ModelSpaceFilterInput | null,
  spaceRootFolderId?: ModelIDInput | null,
};

export type ModelSpaceConnection = {
  __typename: "ModelSpaceConnection",
  items:  Array<Space | null >,
  nextToken?: string | null,
};

export type ModelActiveSpaceFilterInput = {
  owner?: ModelStringInput | null,
  and?: Array< ModelActiveSpaceFilterInput | null > | null,
  or?: Array< ModelActiveSpaceFilterInput | null > | null,
  not?: ModelActiveSpaceFilterInput | null,
  activeSpaceSpaceId?: ModelIDInput | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelActiveSpaceConnection = {
  __typename: "ModelActiveSpaceConnection",
  items:  Array<ActiveSpace | null >,
  nextToken?: string | null,
};

export type ModelActiveProductFilterInput = {
  owner?: ModelStringInput | null,
  and?: Array< ModelActiveProductFilterInput | null > | null,
  or?: Array< ModelActiveProductFilterInput | null > | null,
  not?: ModelActiveProductFilterInput | null,
  activeProductProductId?: ModelIDInput | null,
};

export type ModelActiveProductConnection = {
  __typename: "ModelActiveProductConnection",
  items:  Array<ActiveProduct | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  stripeId?: ModelStringInput | null,
  seenSceneIds?: ModelStringInput | null,
  isSubscriptionCanceled?: ModelBooleanInput | null,
  isReimagineClicked?: ModelBooleanInput | null,
  trialSubscriptionStatus?: ModelTrialSubscriptionStatusInput | null,
  paymentMethodStatus?: ModelPaymentMethodStatusInput | null,
  seenGeneratedPackIds?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelPromptCatalogFilterInput = {
  id?: ModelIDInput | null,
  and?: Array< ModelPromptCatalogFilterInput | null > | null,
  or?: Array< ModelPromptCatalogFilterInput | null > | null,
  not?: ModelPromptCatalogFilterInput | null,
};

export type ModelPromptCatalogConnection = {
  __typename: "ModelPromptCatalogConnection",
  items:  Array<PromptCatalog | null >,
  nextToken?: string | null,
};

export type ModelPromptHistoryFilterInput = {
  description?: ModelStringInput | null,
  score?: ModelIntInput | null,
  username?: ModelStringInput | null,
  and?: Array< ModelPromptHistoryFilterInput | null > | null,
  or?: Array< ModelPromptHistoryFilterInput | null > | null,
  not?: ModelPromptHistoryFilterInput | null,
};

export type ModelPromptHistoryConnection = {
  __typename: "ModelPromptHistoryConnection",
  items:  Array<PromptHistory | null >,
  nextToken?: string | null,
};

export type ModelServiceFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  poorAvailabilityDetectedTime?: ModelStringInput | null,
  and?: Array< ModelServiceFilterInput | null > | null,
  or?: Array< ModelServiceFilterInput | null > | null,
  not?: ModelServiceFilterInput | null,
};

export type ModelServiceConnection = {
  __typename: "ModelServiceConnection",
  items:  Array<Service | null >,
  nextToken?: string | null,
};

export type ModelSceneBundledScenesFilterInput = {
  id?: ModelIDInput | null,
  sceneId?: ModelIDInput | null,
  bundledScenesId?: ModelIDInput | null,
  and?: Array< ModelSceneBundledScenesFilterInput | null > | null,
  or?: Array< ModelSceneBundledScenesFilterInput | null > | null,
  not?: ModelSceneBundledScenesFilterInput | null,
};

export type ModelPackBundledScenesFilterInput = {
  id?: ModelIDInput | null,
  bundledScenesId?: ModelIDInput | null,
  packId?: ModelIDInput | null,
  and?: Array< ModelPackBundledScenesFilterInput | null > | null,
  or?: Array< ModelPackBundledScenesFilterInput | null > | null,
  not?: ModelPackBundledScenesFilterInput | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelSubscriptionProductFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  path?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  productType?: ModelSubscriptionStringInput | null,
  thumbnailPath?: ModelSubscriptionStringInput | null,
  supportedViewType?: ModelSubscriptionStringInput | null,
  industryType?: ModelSubscriptionStringInput | null,
  productFamily?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductFilterInput | null > | null,
};

export type ModelSubscriptionIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  in?: Array< string | null > | null,
  notIn?: Array< string | null > | null,
};

export type ModelSubscriptionProductSampleFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  path?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  thumbnailPath?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProductSampleFilterInput | null > | null,
  or?: Array< ModelSubscriptionProductSampleFilterInput | null > | null,
};

export type ModelSubscriptionSceneFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  path?: ModelSubscriptionStringInput | null,
  name?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  format?: ModelSubscriptionStringInput | null,
  sceneDescription?: ModelSubscriptionStringInput | null,
  includeInPromptCatalog?: ModelSubscriptionBooleanInput | null,
  viewsSupported?: ModelSubscriptionStringInput | null,
  thumbnailPath?: ModelSubscriptionStringInput | null,
  isAnimatable?: ModelSubscriptionBooleanInput | null,
  selectedValuesOfBlocks?: ModelSubscriptionStringInput | null,
  optionsPerBlock?: ModelSubscriptionStringInput | null,
  productSupportedSize?: ModelSubscriptionFloatInput | null,
  hasBlurryBG?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionSceneFilterInput | null > | null,
  or?: Array< ModelSubscriptionSceneFilterInput | null > | null,
};

export type ModelSubscriptionBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
};

export type ModelSubscriptionFloatInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionBundledScenesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  thumbnailPath?: ModelSubscriptionStringInput | null,
  mediaType?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionBundledScenesFilterInput | null > | null,
  or?: Array< ModelSubscriptionBundledScenesFilterInput | null > | null,
};

export type ModelSubscriptionPackFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  description?: ModelSubscriptionStringInput | null,
  thumbnailPath?: ModelSubscriptionStringInput | null,
  level?: ModelSubscriptionIntInput | null,
  tags?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  order?: ModelSubscriptionIntInput | null,
  exampleImages?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPackFilterInput | null > | null,
  or?: Array< ModelSubscriptionPackFilterInput | null > | null,
};

export type ModelSubscriptionIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  in?: Array< number | null > | null,
  notIn?: Array< number | null > | null,
};

export type ModelSubscriptionGeneratedPackFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  status?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionGeneratedPackFilterInput | null > | null,
  or?: Array< ModelSubscriptionGeneratedPackFilterInput | null > | null,
};

export type ModelSubscriptionTrackedProgressItemFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  progress?: ModelSubscriptionIntInput | null,
  and?: Array< ModelSubscriptionTrackedProgressItemFilterInput | null > | null,
  or?: Array< ModelSubscriptionTrackedProgressItemFilterInput | null > | null,
};

export type ModelSubscriptionStoryFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  sceneId?: ModelSubscriptionIDInput | null,
  scenePath?: ModelSubscriptionStringInput | null,
  sceneName?: ModelSubscriptionStringInput | null,
  sceneStatus?: ModelSubscriptionStringInput | null,
  sceneType?: ModelSubscriptionStringInput | null,
  format?: ModelSubscriptionStringInput | null,
  sceneHasBlurryBG?: ModelSubscriptionBooleanInput | null,
  selectedValuesOfBlocks?: ModelSubscriptionStringInput | null,
  optionsPerBlock?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  similarity?: ModelSubscriptionIntInput | null,
  isGeneratedFromHistory?: ModelSubscriptionBooleanInput | null,
  and?: Array< ModelSubscriptionStoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionStoryFilterInput | null > | null,
};

export type ModelSubscriptionProjectFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  selectedValuesOfUserBriefBlocks?: ModelSubscriptionStringInput | null,
  type?: ModelSubscriptionStringInput | null,
  updatedAt?: ModelSubscriptionStringInput | null,
  status?: ModelSubscriptionStringInput | null,
  imagesContstructingThumbnailIds?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionProjectFilterInput | null > | null,
  or?: Array< ModelSubscriptionProjectFilterInput | null > | null,
};

export type ModelSubscriptionFolderFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionFolderFilterInput | null > | null,
  or?: Array< ModelSubscriptionFolderFilterInput | null > | null,
};

export type ModelSubscriptionSpaceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  username?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionSpaceFilterInput | null > | null,
  or?: Array< ModelSubscriptionSpaceFilterInput | null > | null,
};

export type ModelSubscriptionActiveSpaceFilterInput = {
  and?: Array< ModelSubscriptionActiveSpaceFilterInput | null > | null,
  or?: Array< ModelSubscriptionActiveSpaceFilterInput | null > | null,
};

export type ModelSubscriptionActiveProductFilterInput = {
  and?: Array< ModelSubscriptionActiveProductFilterInput | null > | null,
  or?: Array< ModelSubscriptionActiveProductFilterInput | null > | null,
};

export type ModelSubscriptionUserFilterInput = {
  stripeId?: ModelSubscriptionStringInput | null,
  seenSceneIds?: ModelSubscriptionStringInput | null,
  isSubscriptionCanceled?: ModelSubscriptionBooleanInput | null,
  isReimagineClicked?: ModelSubscriptionBooleanInput | null,
  trialSubscriptionStatus?: ModelSubscriptionStringInput | null,
  paymentMethodStatus?: ModelSubscriptionStringInput | null,
  seenGeneratedPackIds?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionUserFilterInput | null > | null,
  or?: Array< ModelSubscriptionUserFilterInput | null > | null,
};

export type ModelSubscriptionPromptCatalogFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPromptCatalogFilterInput | null > | null,
  or?: Array< ModelSubscriptionPromptCatalogFilterInput | null > | null,
};

export type ModelSubscriptionPromptHistoryFilterInput = {
  description?: ModelSubscriptionStringInput | null,
  score?: ModelSubscriptionIntInput | null,
  username?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionPromptHistoryFilterInput | null > | null,
  or?: Array< ModelSubscriptionPromptHistoryFilterInput | null > | null,
};

export type ModelSubscriptionServiceFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  name?: ModelSubscriptionStringInput | null,
  poorAvailabilityDetectedTime?: ModelSubscriptionStringInput | null,
  and?: Array< ModelSubscriptionServiceFilterInput | null > | null,
  or?: Array< ModelSubscriptionServiceFilterInput | null > | null,
};

export type ModelSubscriptionSceneBundledScenesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  sceneId?: ModelSubscriptionIDInput | null,
  bundledScenesId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionSceneBundledScenesFilterInput | null > | null,
  or?: Array< ModelSubscriptionSceneBundledScenesFilterInput | null > | null,
};

export type ModelSubscriptionPackBundledScenesFilterInput = {
  id?: ModelSubscriptionIDInput | null,
  bundledScenesId?: ModelSubscriptionIDInput | null,
  packId?: ModelSubscriptionIDInput | null,
  and?: Array< ModelSubscriptionPackBundledScenesFilterInput | null > | null,
  or?: Array< ModelSubscriptionPackBundledScenesFilterInput | null > | null,
};

export type CreateProductMutationVariables = {
  input: CreateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type CreateProductMutation = {
  createProduct?:  {
    __typename: "Product",
    id: string,
    path: string,
    name: string,
    status: ProductStatus,
    productType?: ProductType | null,
    thumbnailPath?: string | null,
    supportedViewType?: Array< ViewType | null > | null,
    industryType?: string | null,
    productFamily?: string | null,
    size?:  {
      __typename: "ProductSize",
      width?: number | null,
      height?: number | null,
      depth?: number | null,
    } | null,
    assets?:  Array< {
      __typename: "ProductAsset",
      url?: string | null,
    } | null > | null,
    dataForInvoice?:  {
      __typename: "ProductDataForInvoice",
      customProductComplexityRank?: number | null,
      priceId?: string | null,
      immersiveProductId?: string | null,
      priceValue?: number | null,
      priceCurrency?: string | null,
    } | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductMutationVariables = {
  input: UpdateProductInput,
  condition?: ModelProductConditionInput | null,
};

export type UpdateProductMutation = {
  updateProduct?:  {
    __typename: "Product",
    id: string,
    path: string,
    name: string,
    status: ProductStatus,
    productType?: ProductType | null,
    thumbnailPath?: string | null,
    supportedViewType?: Array< ViewType | null > | null,
    industryType?: string | null,
    productFamily?: string | null,
    size?:  {
      __typename: "ProductSize",
      width?: number | null,
      height?: number | null,
      depth?: number | null,
    } | null,
    assets?:  Array< {
      __typename: "ProductAsset",
      url?: string | null,
    } | null > | null,
    dataForInvoice?:  {
      __typename: "ProductDataForInvoice",
      customProductComplexityRank?: number | null,
      priceId?: string | null,
      immersiveProductId?: string | null,
      priceValue?: number | null,
      priceCurrency?: string | null,
    } | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProductMutationVariables = {
  input: DeleteProductInput,
  condition?: ModelProductConditionInput | null,
};

export type DeleteProductMutation = {
  deleteProduct?:  {
    __typename: "Product",
    id: string,
    path: string,
    name: string,
    status: ProductStatus,
    productType?: ProductType | null,
    thumbnailPath?: string | null,
    supportedViewType?: Array< ViewType | null > | null,
    industryType?: string | null,
    productFamily?: string | null,
    size?:  {
      __typename: "ProductSize",
      width?: number | null,
      height?: number | null,
      depth?: number | null,
    } | null,
    assets?:  Array< {
      __typename: "ProductAsset",
      url?: string | null,
    } | null > | null,
    dataForInvoice?:  {
      __typename: "ProductDataForInvoice",
      customProductComplexityRank?: number | null,
      priceId?: string | null,
      immersiveProductId?: string | null,
      priceValue?: number | null,
      priceCurrency?: string | null,
    } | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateProductSampleMutationVariables = {
  input: CreateProductSampleInput,
  condition?: ModelProductSampleConditionInput | null,
};

export type CreateProductSampleMutation = {
  createProductSample?:  {
    __typename: "ProductSample",
    id: string,
    path: string,
    name: string,
    thumbnailPath: string,
    size?:  {
      __typename: "ProductSize",
      width?: number | null,
      height?: number | null,
      depth?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateProductSampleMutationVariables = {
  input: UpdateProductSampleInput,
  condition?: ModelProductSampleConditionInput | null,
};

export type UpdateProductSampleMutation = {
  updateProductSample?:  {
    __typename: "ProductSample",
    id: string,
    path: string,
    name: string,
    thumbnailPath: string,
    size?:  {
      __typename: "ProductSize",
      width?: number | null,
      height?: number | null,
      depth?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteProductSampleMutationVariables = {
  input: DeleteProductSampleInput,
  condition?: ModelProductSampleConditionInput | null,
};

export type DeleteProductSampleMutation = {
  deleteProductSample?:  {
    __typename: "ProductSample",
    id: string,
    path: string,
    name: string,
    thumbnailPath: string,
    size?:  {
      __typename: "ProductSize",
      width?: number | null,
      height?: number | null,
      depth?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateSceneMutationVariables = {
  input: CreateSceneInput,
  condition?: ModelSceneConditionInput | null,
};

export type CreateSceneMutation = {
  createScene?:  {
    __typename: "Scene",
    id: string,
    path: string,
    name: string,
    brief:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    },
    status: SceneStatus,
    type?: SceneType | null,
    format?: SceneFormat | null,
    sceneDescription?: string | null,
    includeInPromptCatalog?: boolean | null,
    viewsSupported?: Array< ViewType | null > | null,
    thumbnailPath?: string | null,
    isAnimatable?: boolean | null,
    selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
    optionsPerBlock?: Array< Array< string | null > | null > | null,
    themes?:  Array< {
      __typename: "SceneTheme",
      main?: string | null,
      sub?: string | null,
    } | null > | null,
    productSupportedSize?: number | null,
    hasBlurryBG?: boolean | null,
    bundledScenes?:  {
      __typename: "ModelSceneBundledScenesConnection",
      items:  Array< {
        __typename: "SceneBundledScenes",
        id: string,
        sceneId: string,
        bundledScenesId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateSceneMutationVariables = {
  input: UpdateSceneInput,
  condition?: ModelSceneConditionInput | null,
};

export type UpdateSceneMutation = {
  updateScene?:  {
    __typename: "Scene",
    id: string,
    path: string,
    name: string,
    brief:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    },
    status: SceneStatus,
    type?: SceneType | null,
    format?: SceneFormat | null,
    sceneDescription?: string | null,
    includeInPromptCatalog?: boolean | null,
    viewsSupported?: Array< ViewType | null > | null,
    thumbnailPath?: string | null,
    isAnimatable?: boolean | null,
    selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
    optionsPerBlock?: Array< Array< string | null > | null > | null,
    themes?:  Array< {
      __typename: "SceneTheme",
      main?: string | null,
      sub?: string | null,
    } | null > | null,
    productSupportedSize?: number | null,
    hasBlurryBG?: boolean | null,
    bundledScenes?:  {
      __typename: "ModelSceneBundledScenesConnection",
      items:  Array< {
        __typename: "SceneBundledScenes",
        id: string,
        sceneId: string,
        bundledScenesId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteSceneMutationVariables = {
  input: DeleteSceneInput,
  condition?: ModelSceneConditionInput | null,
};

export type DeleteSceneMutation = {
  deleteScene?:  {
    __typename: "Scene",
    id: string,
    path: string,
    name: string,
    brief:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    },
    status: SceneStatus,
    type?: SceneType | null,
    format?: SceneFormat | null,
    sceneDescription?: string | null,
    includeInPromptCatalog?: boolean | null,
    viewsSupported?: Array< ViewType | null > | null,
    thumbnailPath?: string | null,
    isAnimatable?: boolean | null,
    selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
    optionsPerBlock?: Array< Array< string | null > | null > | null,
    themes?:  Array< {
      __typename: "SceneTheme",
      main?: string | null,
      sub?: string | null,
    } | null > | null,
    productSupportedSize?: number | null,
    hasBlurryBG?: boolean | null,
    bundledScenes?:  {
      __typename: "ModelSceneBundledScenesConnection",
      items:  Array< {
        __typename: "SceneBundledScenes",
        id: string,
        sceneId: string,
        bundledScenesId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateBundledScenesMutationVariables = {
  input: CreateBundledScenesInput,
  condition?: ModelBundledScenesConditionInput | null,
};

export type CreateBundledScenesMutation = {
  createBundledScenes?:  {
    __typename: "BundledScenes",
    id: string,
    name?: string | null,
    description?: string | null,
    thumbnailPath?: string | null,
    scenes?:  {
      __typename: "ModelSceneBundledScenesConnection",
      items:  Array< {
        __typename: "SceneBundledScenes",
        id: string,
        sceneId: string,
        bundledScenesId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    packs?:  {
      __typename: "ModelPackBundledScenesConnection",
      items:  Array< {
        __typename: "PackBundledScenes",
        id: string,
        bundledScenesId: string,
        packId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    mediaType?: MediaType | null,
    status?: BundledScenesStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateBundledScenesMutationVariables = {
  input: UpdateBundledScenesInput,
  condition?: ModelBundledScenesConditionInput | null,
};

export type UpdateBundledScenesMutation = {
  updateBundledScenes?:  {
    __typename: "BundledScenes",
    id: string,
    name?: string | null,
    description?: string | null,
    thumbnailPath?: string | null,
    scenes?:  {
      __typename: "ModelSceneBundledScenesConnection",
      items:  Array< {
        __typename: "SceneBundledScenes",
        id: string,
        sceneId: string,
        bundledScenesId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    packs?:  {
      __typename: "ModelPackBundledScenesConnection",
      items:  Array< {
        __typename: "PackBundledScenes",
        id: string,
        bundledScenesId: string,
        packId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    mediaType?: MediaType | null,
    status?: BundledScenesStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteBundledScenesMutationVariables = {
  input: DeleteBundledScenesInput,
  condition?: ModelBundledScenesConditionInput | null,
};

export type DeleteBundledScenesMutation = {
  deleteBundledScenes?:  {
    __typename: "BundledScenes",
    id: string,
    name?: string | null,
    description?: string | null,
    thumbnailPath?: string | null,
    scenes?:  {
      __typename: "ModelSceneBundledScenesConnection",
      items:  Array< {
        __typename: "SceneBundledScenes",
        id: string,
        sceneId: string,
        bundledScenesId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    packs?:  {
      __typename: "ModelPackBundledScenesConnection",
      items:  Array< {
        __typename: "PackBundledScenes",
        id: string,
        bundledScenesId: string,
        packId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    mediaType?: MediaType | null,
    status?: BundledScenesStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreatePackMutationVariables = {
  input: CreatePackInput,
  condition?: ModelPackConditionInput | null,
};

export type CreatePackMutation = {
  createPack?:  {
    __typename: "Pack",
    id: string,
    name?: string | null,
    description?: string | null,
    thumbnailPath?: string | null,
    level?: number | null,
    bundledScenes?:  {
      __typename: "ModelPackBundledScenesConnection",
      items:  Array< {
        __typename: "PackBundledScenes",
        id: string,
        bundledScenesId: string,
        packId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tags?: Array< PackTag | null > | null,
    status?: PackStatus | null,
    order?: number | null,
    exampleImages?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePackMutationVariables = {
  input: UpdatePackInput,
  condition?: ModelPackConditionInput | null,
};

export type UpdatePackMutation = {
  updatePack?:  {
    __typename: "Pack",
    id: string,
    name?: string | null,
    description?: string | null,
    thumbnailPath?: string | null,
    level?: number | null,
    bundledScenes?:  {
      __typename: "ModelPackBundledScenesConnection",
      items:  Array< {
        __typename: "PackBundledScenes",
        id: string,
        bundledScenesId: string,
        packId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tags?: Array< PackTag | null > | null,
    status?: PackStatus | null,
    order?: number | null,
    exampleImages?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePackMutationVariables = {
  input: DeletePackInput,
  condition?: ModelPackConditionInput | null,
};

export type DeletePackMutation = {
  deletePack?:  {
    __typename: "Pack",
    id: string,
    name?: string | null,
    description?: string | null,
    thumbnailPath?: string | null,
    level?: number | null,
    bundledScenes?:  {
      __typename: "ModelPackBundledScenesConnection",
      items:  Array< {
        __typename: "PackBundledScenes",
        id: string,
        bundledScenesId: string,
        packId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tags?: Array< PackTag | null > | null,
    status?: PackStatus | null,
    order?: number | null,
    exampleImages?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateGeneratedPackMutationVariables = {
  input: CreateGeneratedPackInput,
  condition?: ModelGeneratedPackConditionInput | null,
};

export type CreateGeneratedPackMutation = {
  createGeneratedPack?:  {
    __typename: "GeneratedPack",
    id: string,
    pack?:  {
      __typename: "Pack",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      level?: number | null,
      bundledScenes?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      tags?: Array< PackTag | null > | null,
      status?: PackStatus | null,
      order?: number | null,
      exampleImages?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    product?:  {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    status?: GeneratedPackStatus | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    generatedPackPackId?: string | null,
    generatedPackProductId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateGeneratedPackMutationVariables = {
  input: UpdateGeneratedPackInput,
  condition?: ModelGeneratedPackConditionInput | null,
};

export type UpdateGeneratedPackMutation = {
  updateGeneratedPack?:  {
    __typename: "GeneratedPack",
    id: string,
    pack?:  {
      __typename: "Pack",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      level?: number | null,
      bundledScenes?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      tags?: Array< PackTag | null > | null,
      status?: PackStatus | null,
      order?: number | null,
      exampleImages?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    product?:  {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    status?: GeneratedPackStatus | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    generatedPackPackId?: string | null,
    generatedPackProductId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteGeneratedPackMutationVariables = {
  input: DeleteGeneratedPackInput,
  condition?: ModelGeneratedPackConditionInput | null,
};

export type DeleteGeneratedPackMutation = {
  deleteGeneratedPack?:  {
    __typename: "GeneratedPack",
    id: string,
    pack?:  {
      __typename: "Pack",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      level?: number | null,
      bundledScenes?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      tags?: Array< PackTag | null > | null,
      status?: PackStatus | null,
      order?: number | null,
      exampleImages?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    product?:  {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    status?: GeneratedPackStatus | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    generatedPackPackId?: string | null,
    generatedPackProductId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateTrackedProgressItemMutationVariables = {
  input: CreateTrackedProgressItemInput,
  condition?: ModelTrackedProgressItemConditionInput | null,
};

export type CreateTrackedProgressItemMutation = {
  createTrackedProgressItem?:  {
    __typename: "TrackedProgressItem",
    id: string,
    progress: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateTrackedProgressItemMutationVariables = {
  input: UpdateTrackedProgressItemInput,
  condition?: ModelTrackedProgressItemConditionInput | null,
};

export type UpdateTrackedProgressItemMutation = {
  updateTrackedProgressItem?:  {
    __typename: "TrackedProgressItem",
    id: string,
    progress: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteTrackedProgressItemMutationVariables = {
  input: DeleteTrackedProgressItemInput,
  condition?: ModelTrackedProgressItemConditionInput | null,
};

export type DeleteTrackedProgressItemMutation = {
  deleteTrackedProgressItem?:  {
    __typename: "TrackedProgressItem",
    id: string,
    progress: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateStoryMutationVariables = {
  input: CreateStoryInput,
  condition?: ModelStoryConditionInput | null,
};

export type CreateStoryMutation = {
  createStory?:  {
    __typename: "Story",
    id: string,
    sceneId: string,
    scenePath: string,
    sceneName: string,
    sceneBrief:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    },
    sceneStatus: SceneStatus,
    sceneType?: SceneType | null,
    format?: SceneFormat | null,
    sceneHasBlurryBG?: boolean | null,
    selectedValuesOfBlocks?: Array< string | null > | null,
    optionsPerBlock?: Array< Array< string | null > | null > | null,
    status?: StoryStatus | null,
    similarity?: number | null,
    isGeneratedFromHistory?: boolean | null,
    reimaginesData?:  Array< {
      __typename: "ReimagineData",
      s3key?: string | null,
      type?: ReimagineType | null,
      selectedValuesOfBlocks?: Array< string | null > | null,
      reimagineInfo?:  {
        __typename: "ReimagineInfo",
        option?: string | null,
        category?: string | null,
        preset?: string | null,
      } | null,
    } | null > | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    projectStoriesId?: string | null,
  } | null,
};

export type UpdateStoryMutationVariables = {
  input: UpdateStoryInput,
  condition?: ModelStoryConditionInput | null,
};

export type UpdateStoryMutation = {
  updateStory?:  {
    __typename: "Story",
    id: string,
    sceneId: string,
    scenePath: string,
    sceneName: string,
    sceneBrief:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    },
    sceneStatus: SceneStatus,
    sceneType?: SceneType | null,
    format?: SceneFormat | null,
    sceneHasBlurryBG?: boolean | null,
    selectedValuesOfBlocks?: Array< string | null > | null,
    optionsPerBlock?: Array< Array< string | null > | null > | null,
    status?: StoryStatus | null,
    similarity?: number | null,
    isGeneratedFromHistory?: boolean | null,
    reimaginesData?:  Array< {
      __typename: "ReimagineData",
      s3key?: string | null,
      type?: ReimagineType | null,
      selectedValuesOfBlocks?: Array< string | null > | null,
      reimagineInfo?:  {
        __typename: "ReimagineInfo",
        option?: string | null,
        category?: string | null,
        preset?: string | null,
      } | null,
    } | null > | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    projectStoriesId?: string | null,
  } | null,
};

export type DeleteStoryMutationVariables = {
  input: DeleteStoryInput,
  condition?: ModelStoryConditionInput | null,
};

export type DeleteStoryMutation = {
  deleteStory?:  {
    __typename: "Story",
    id: string,
    sceneId: string,
    scenePath: string,
    sceneName: string,
    sceneBrief:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    },
    sceneStatus: SceneStatus,
    sceneType?: SceneType | null,
    format?: SceneFormat | null,
    sceneHasBlurryBG?: boolean | null,
    selectedValuesOfBlocks?: Array< string | null > | null,
    optionsPerBlock?: Array< Array< string | null > | null > | null,
    status?: StoryStatus | null,
    similarity?: number | null,
    isGeneratedFromHistory?: boolean | null,
    reimaginesData?:  Array< {
      __typename: "ReimagineData",
      s3key?: string | null,
      type?: ReimagineType | null,
      selectedValuesOfBlocks?: Array< string | null > | null,
      reimagineInfo?:  {
        __typename: "ReimagineInfo",
        option?: string | null,
        category?: string | null,
        preset?: string | null,
      } | null,
    } | null > | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    projectStoriesId?: string | null,
  } | null,
};

export type CreateProjectMutationVariables = {
  input: CreateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type CreateProjectMutation = {
  createProject?:  {
    __typename: "Project",
    id: string,
    name?: string | null,
    userBrief?:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    } | null,
    selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
    stories?:  {
      __typename: "ModelStoryConnection",
      items:  Array< {
        __typename: "Story",
        id: string,
        sceneId: string,
        scenePath: string,
        sceneName: string,
        sceneStatus: SceneStatus,
        sceneType?: SceneType | null,
        format?: SceneFormat | null,
        sceneHasBlurryBG?: boolean | null,
        selectedValuesOfBlocks?: Array< string | null > | null,
        optionsPerBlock?: Array< Array< string | null > | null > | null,
        status?: StoryStatus | null,
        similarity?: number | null,
        isGeneratedFromHistory?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        projectStoriesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    containingFolder?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    type: string,
    updatedAt: string,
    status?: ProjectStatus | null,
    wizardDialogData?:  {
      __typename: "WizardDialogData",
      step_1?: string | null,
      step_2?: string | null,
    } | null,
    imagesContstructingThumbnailIds?: Array< string | null > | null,
    username: string,
    createdAt: string,
    folderProjectsId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateProjectMutationVariables = {
  input: UpdateProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type UpdateProjectMutation = {
  updateProject?:  {
    __typename: "Project",
    id: string,
    name?: string | null,
    userBrief?:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    } | null,
    selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
    stories?:  {
      __typename: "ModelStoryConnection",
      items:  Array< {
        __typename: "Story",
        id: string,
        sceneId: string,
        scenePath: string,
        sceneName: string,
        sceneStatus: SceneStatus,
        sceneType?: SceneType | null,
        format?: SceneFormat | null,
        sceneHasBlurryBG?: boolean | null,
        selectedValuesOfBlocks?: Array< string | null > | null,
        optionsPerBlock?: Array< Array< string | null > | null > | null,
        status?: StoryStatus | null,
        similarity?: number | null,
        isGeneratedFromHistory?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        projectStoriesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    containingFolder?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    type: string,
    updatedAt: string,
    status?: ProjectStatus | null,
    wizardDialogData?:  {
      __typename: "WizardDialogData",
      step_1?: string | null,
      step_2?: string | null,
    } | null,
    imagesContstructingThumbnailIds?: Array< string | null > | null,
    username: string,
    createdAt: string,
    folderProjectsId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteProjectMutationVariables = {
  input: DeleteProjectInput,
  condition?: ModelProjectConditionInput | null,
};

export type DeleteProjectMutation = {
  deleteProject?:  {
    __typename: "Project",
    id: string,
    name?: string | null,
    userBrief?:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    } | null,
    selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
    stories?:  {
      __typename: "ModelStoryConnection",
      items:  Array< {
        __typename: "Story",
        id: string,
        sceneId: string,
        scenePath: string,
        sceneName: string,
        sceneStatus: SceneStatus,
        sceneType?: SceneType | null,
        format?: SceneFormat | null,
        sceneHasBlurryBG?: boolean | null,
        selectedValuesOfBlocks?: Array< string | null > | null,
        optionsPerBlock?: Array< Array< string | null > | null > | null,
        status?: StoryStatus | null,
        similarity?: number | null,
        isGeneratedFromHistory?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        projectStoriesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    containingFolder?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    type: string,
    updatedAt: string,
    status?: ProjectStatus | null,
    wizardDialogData?:  {
      __typename: "WizardDialogData",
      step_1?: string | null,
      step_2?: string | null,
    } | null,
    imagesContstructingThumbnailIds?: Array< string | null > | null,
    username: string,
    createdAt: string,
    folderProjectsId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateFolderMutationVariables = {
  input: CreateFolderInput,
  condition?: ModelFolderConditionInput | null,
};

export type CreateFolderMutation = {
  createFolder?:  {
    __typename: "Folder",
    id: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name?: string | null,
        selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
        type: string,
        updatedAt: string,
        status?: ProjectStatus | null,
        imagesContstructingThumbnailIds?: Array< string | null > | null,
        username: string,
        createdAt: string,
        folderProjectsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    childFolders?:  {
      __typename: "ModelFolderConnection",
      items:  Array< {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    parent?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    folderChildFoldersId?: string | null,
    folderParentId?: string | null,
  } | null,
};

export type UpdateFolderMutationVariables = {
  input: UpdateFolderInput,
  condition?: ModelFolderConditionInput | null,
};

export type UpdateFolderMutation = {
  updateFolder?:  {
    __typename: "Folder",
    id: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name?: string | null,
        selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
        type: string,
        updatedAt: string,
        status?: ProjectStatus | null,
        imagesContstructingThumbnailIds?: Array< string | null > | null,
        username: string,
        createdAt: string,
        folderProjectsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    childFolders?:  {
      __typename: "ModelFolderConnection",
      items:  Array< {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    parent?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    folderChildFoldersId?: string | null,
    folderParentId?: string | null,
  } | null,
};

export type DeleteFolderMutationVariables = {
  input: DeleteFolderInput,
  condition?: ModelFolderConditionInput | null,
};

export type DeleteFolderMutation = {
  deleteFolder?:  {
    __typename: "Folder",
    id: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name?: string | null,
        selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
        type: string,
        updatedAt: string,
        status?: ProjectStatus | null,
        imagesContstructingThumbnailIds?: Array< string | null > | null,
        username: string,
        createdAt: string,
        folderProjectsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    childFolders?:  {
      __typename: "ModelFolderConnection",
      items:  Array< {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    parent?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    folderChildFoldersId?: string | null,
    folderParentId?: string | null,
  } | null,
};

export type CreateSpaceMutationVariables = {
  input: CreateSpaceInput,
  condition?: ModelSpaceConditionInput | null,
};

export type CreateSpaceMutation = {
  createSpace?:  {
    __typename: "Space",
    id: string,
    name: string,
    rootFolder?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    spaceRootFolderId?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateSpaceMutationVariables = {
  input: UpdateSpaceInput,
  condition?: ModelSpaceConditionInput | null,
};

export type UpdateSpaceMutation = {
  updateSpace?:  {
    __typename: "Space",
    id: string,
    name: string,
    rootFolder?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    spaceRootFolderId?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteSpaceMutationVariables = {
  input: DeleteSpaceInput,
  condition?: ModelSpaceConditionInput | null,
};

export type DeleteSpaceMutation = {
  deleteSpace?:  {
    __typename: "Space",
    id: string,
    name: string,
    rootFolder?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    spaceRootFolderId?: string | null,
    owner?: string | null,
  } | null,
};

export type CreateActiveSpaceMutationVariables = {
  input: CreateActiveSpaceInput,
  condition?: ModelActiveSpaceConditionInput | null,
};

export type CreateActiveSpaceMutation = {
  createActiveSpace?:  {
    __typename: "ActiveSpace",
    space?:  {
      __typename: "Space",
      id: string,
      name: string,
      rootFolder?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      spaceRootFolderId?: string | null,
      owner?: string | null,
    } | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    activeSpaceSpaceId?: string | null,
  } | null,
};

export type UpdateActiveSpaceMutationVariables = {
  input: UpdateActiveSpaceInput,
  condition?: ModelActiveSpaceConditionInput | null,
};

export type UpdateActiveSpaceMutation = {
  updateActiveSpace?:  {
    __typename: "ActiveSpace",
    space?:  {
      __typename: "Space",
      id: string,
      name: string,
      rootFolder?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      spaceRootFolderId?: string | null,
      owner?: string | null,
    } | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    activeSpaceSpaceId?: string | null,
  } | null,
};

export type DeleteActiveSpaceMutationVariables = {
  input: DeleteActiveSpaceInput,
  condition?: ModelActiveSpaceConditionInput | null,
};

export type DeleteActiveSpaceMutation = {
  deleteActiveSpace?:  {
    __typename: "ActiveSpace",
    space?:  {
      __typename: "Space",
      id: string,
      name: string,
      rootFolder?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      spaceRootFolderId?: string | null,
      owner?: string | null,
    } | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    activeSpaceSpaceId?: string | null,
  } | null,
};

export type CreateActiveProductMutationVariables = {
  input: CreateActiveProductInput,
  condition?: ModelActiveProductConditionInput | null,
};

export type CreateActiveProductMutation = {
  createActiveProduct?:  {
    __typename: "ActiveProduct",
    product?:  {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    activeProductProductId?: string | null,
  } | null,
};

export type UpdateActiveProductMutationVariables = {
  input: UpdateActiveProductInput,
  condition?: ModelActiveProductConditionInput | null,
};

export type UpdateActiveProductMutation = {
  updateActiveProduct?:  {
    __typename: "ActiveProduct",
    product?:  {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    activeProductProductId?: string | null,
  } | null,
};

export type DeleteActiveProductMutationVariables = {
  input: DeleteActiveProductInput,
  condition?: ModelActiveProductConditionInput | null,
};

export type DeleteActiveProductMutation = {
  deleteActiveProduct?:  {
    __typename: "ActiveProduct",
    product?:  {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    activeProductProductId?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    onboarding:  {
      __typename: "OnboardingType",
      formSteps?:  Array< {
        __typename: "FormSteps",
        id?: number | null,
        data?: string | null,
        isSubmited?: boolean | null,
      } | null > | null,
      uiSteps?:  Array< {
        __typename: "UISteps",
        id?: number | null,
        completedStepTypes?: Array< UIStepType | null > | null,
        isSubmited?: boolean | null,
      } | null > | null,
    },
    roles?:  Array< {
      __typename: "UserAttribute",
      id?: number | null,
      value?: string | null,
    } | null > | null,
    goals?:  Array< {
      __typename: "UserAttribute",
      id?: number | null,
      value?: string | null,
    } | null > | null,
    stripeId?: string | null,
    limitation?:  {
      __typename: "LimitationType",
      generatedStoriesInLimitPeriod?: number | null,
      createdProjectsInLimitPeriod?: number | null,
      createdCustomProductsInLimitPeriod?: number | null,
      createdStandardProductsInLimitPeriod?: number | null,
      generatedReimagines?: number | null,
      downloadedImages?: number | null,
      downloadedVideos?: number | null,
      generatedPacks?: number | null,
      subscriptionStartDate?: string | null,
      limitResetDate?: string | null,
      projectLimit?: number | null,
      storiesLimit?: number | null,
      storiesLimitPeriod?: string | null,
      customProducts?: number | null,
      standardProducts?: number | null,
      images?: number | null,
      videos?: number | null,
      packsLimit?: number | null,
    } | null,
    seenSceneIds?: Array< string | null > | null,
    isSubscriptionCanceled?: boolean | null,
    isReimagineClicked?: boolean | null,
    trialSubscriptionStatus?: TrialSubscriptionStatus | null,
    location?:  {
      __typename: "Location",
      country?: string | null,
      countryName?: string | null,
      countryCode?: string | null,
      countryCodeISO3?: string | null,
      currency?: string | null,
      currencyName?: string | null,
      languages?: string | null,
    } | null,
    paymentMethodStatus?: PaymentMethodStatus | null,
    seenGeneratedPackIds?: Array< string | null > | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    onboarding:  {
      __typename: "OnboardingType",
      formSteps?:  Array< {
        __typename: "FormSteps",
        id?: number | null,
        data?: string | null,
        isSubmited?: boolean | null,
      } | null > | null,
      uiSteps?:  Array< {
        __typename: "UISteps",
        id?: number | null,
        completedStepTypes?: Array< UIStepType | null > | null,
        isSubmited?: boolean | null,
      } | null > | null,
    },
    roles?:  Array< {
      __typename: "UserAttribute",
      id?: number | null,
      value?: string | null,
    } | null > | null,
    goals?:  Array< {
      __typename: "UserAttribute",
      id?: number | null,
      value?: string | null,
    } | null > | null,
    stripeId?: string | null,
    limitation?:  {
      __typename: "LimitationType",
      generatedStoriesInLimitPeriod?: number | null,
      createdProjectsInLimitPeriod?: number | null,
      createdCustomProductsInLimitPeriod?: number | null,
      createdStandardProductsInLimitPeriod?: number | null,
      generatedReimagines?: number | null,
      downloadedImages?: number | null,
      downloadedVideos?: number | null,
      generatedPacks?: number | null,
      subscriptionStartDate?: string | null,
      limitResetDate?: string | null,
      projectLimit?: number | null,
      storiesLimit?: number | null,
      storiesLimitPeriod?: string | null,
      customProducts?: number | null,
      standardProducts?: number | null,
      images?: number | null,
      videos?: number | null,
      packsLimit?: number | null,
    } | null,
    seenSceneIds?: Array< string | null > | null,
    isSubscriptionCanceled?: boolean | null,
    isReimagineClicked?: boolean | null,
    trialSubscriptionStatus?: TrialSubscriptionStatus | null,
    location?:  {
      __typename: "Location",
      country?: string | null,
      countryName?: string | null,
      countryCode?: string | null,
      countryCodeISO3?: string | null,
      currency?: string | null,
      currencyName?: string | null,
      languages?: string | null,
    } | null,
    paymentMethodStatus?: PaymentMethodStatus | null,
    seenGeneratedPackIds?: Array< string | null > | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    onboarding:  {
      __typename: "OnboardingType",
      formSteps?:  Array< {
        __typename: "FormSteps",
        id?: number | null,
        data?: string | null,
        isSubmited?: boolean | null,
      } | null > | null,
      uiSteps?:  Array< {
        __typename: "UISteps",
        id?: number | null,
        completedStepTypes?: Array< UIStepType | null > | null,
        isSubmited?: boolean | null,
      } | null > | null,
    },
    roles?:  Array< {
      __typename: "UserAttribute",
      id?: number | null,
      value?: string | null,
    } | null > | null,
    goals?:  Array< {
      __typename: "UserAttribute",
      id?: number | null,
      value?: string | null,
    } | null > | null,
    stripeId?: string | null,
    limitation?:  {
      __typename: "LimitationType",
      generatedStoriesInLimitPeriod?: number | null,
      createdProjectsInLimitPeriod?: number | null,
      createdCustomProductsInLimitPeriod?: number | null,
      createdStandardProductsInLimitPeriod?: number | null,
      generatedReimagines?: number | null,
      downloadedImages?: number | null,
      downloadedVideos?: number | null,
      generatedPacks?: number | null,
      subscriptionStartDate?: string | null,
      limitResetDate?: string | null,
      projectLimit?: number | null,
      storiesLimit?: number | null,
      storiesLimitPeriod?: string | null,
      customProducts?: number | null,
      standardProducts?: number | null,
      images?: number | null,
      videos?: number | null,
      packsLimit?: number | null,
    } | null,
    seenSceneIds?: Array< string | null > | null,
    isSubscriptionCanceled?: boolean | null,
    isReimagineClicked?: boolean | null,
    trialSubscriptionStatus?: TrialSubscriptionStatus | null,
    location?:  {
      __typename: "Location",
      country?: string | null,
      countryName?: string | null,
      countryCode?: string | null,
      countryCodeISO3?: string | null,
      currency?: string | null,
      currencyName?: string | null,
      languages?: string | null,
    } | null,
    paymentMethodStatus?: PaymentMethodStatus | null,
    seenGeneratedPackIds?: Array< string | null > | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePromptCatalogMutationVariables = {
  input: CreatePromptCatalogInput,
  condition?: ModelPromptCatalogConditionInput | null,
};

export type CreatePromptCatalogMutation = {
  createPromptCatalog?:  {
    __typename: "PromptCatalog",
    id: string,
    colors?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    elements?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    descriptions?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePromptCatalogMutationVariables = {
  input: UpdatePromptCatalogInput,
  condition?: ModelPromptCatalogConditionInput | null,
};

export type UpdatePromptCatalogMutation = {
  updatePromptCatalog?:  {
    __typename: "PromptCatalog",
    id: string,
    colors?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    elements?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    descriptions?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePromptCatalogMutationVariables = {
  input: DeletePromptCatalogInput,
  condition?: ModelPromptCatalogConditionInput | null,
};

export type DeletePromptCatalogMutation = {
  deletePromptCatalog?:  {
    __typename: "PromptCatalog",
    id: string,
    colors?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    elements?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    descriptions?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreatePromptHistoryMutationVariables = {
  input: CreatePromptHistoryInput,
  condition?: ModelPromptHistoryConditionInput | null,
};

export type CreatePromptHistoryMutation = {
  createPromptHistory?:  {
    __typename: "PromptHistory",
    description?: string | null,
    score?: Array< number | null > | null,
    username: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePromptHistoryMutationVariables = {
  input: UpdatePromptHistoryInput,
  condition?: ModelPromptHistoryConditionInput | null,
};

export type UpdatePromptHistoryMutation = {
  updatePromptHistory?:  {
    __typename: "PromptHistory",
    description?: string | null,
    score?: Array< number | null > | null,
    username: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePromptHistoryMutationVariables = {
  input: DeletePromptHistoryInput,
  condition?: ModelPromptHistoryConditionInput | null,
};

export type DeletePromptHistoryMutation = {
  deletePromptHistory?:  {
    __typename: "PromptHistory",
    description?: string | null,
    score?: Array< number | null > | null,
    username: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateServiceMutationVariables = {
  input: CreateServiceInput,
  condition?: ModelServiceConditionInput | null,
};

export type CreateServiceMutation = {
  createService?:  {
    __typename: "Service",
    id: string,
    name: string,
    poorAvailabilityDetectedTime?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateServiceMutationVariables = {
  input: UpdateServiceInput,
  condition?: ModelServiceConditionInput | null,
};

export type UpdateServiceMutation = {
  updateService?:  {
    __typename: "Service",
    id: string,
    name: string,
    poorAvailabilityDetectedTime?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteServiceMutationVariables = {
  input: DeleteServiceInput,
  condition?: ModelServiceConditionInput | null,
};

export type DeleteServiceMutation = {
  deleteService?:  {
    __typename: "Service",
    id: string,
    name: string,
    poorAvailabilityDetectedTime?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateSceneBundledScenesMutationVariables = {
  input: CreateSceneBundledScenesInput,
  condition?: ModelSceneBundledScenesConditionInput | null,
};

export type CreateSceneBundledScenesMutation = {
  createSceneBundledScenes?:  {
    __typename: "SceneBundledScenes",
    id: string,
    sceneId: string,
    bundledScenesId: string,
    scene:  {
      __typename: "Scene",
      id: string,
      path: string,
      name: string,
      brief:  {
        __typename: "Brief",
        description?: string | null,
        blocks?: Array< BlockType | null > | null,
      },
      status: SceneStatus,
      type?: SceneType | null,
      format?: SceneFormat | null,
      sceneDescription?: string | null,
      includeInPromptCatalog?: boolean | null,
      viewsSupported?: Array< ViewType | null > | null,
      thumbnailPath?: string | null,
      isAnimatable?: boolean | null,
      selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
      optionsPerBlock?: Array< Array< string | null > | null > | null,
      themes?:  Array< {
        __typename: "SceneTheme",
        main?: string | null,
        sub?: string | null,
      } | null > | null,
      productSupportedSize?: number | null,
      hasBlurryBG?: boolean | null,
      bundledScenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    bundledScenes:  {
      __typename: "BundledScenes",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      scenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      packs?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      mediaType?: MediaType | null,
      status?: BundledScenesStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateSceneBundledScenesMutationVariables = {
  input: UpdateSceneBundledScenesInput,
  condition?: ModelSceneBundledScenesConditionInput | null,
};

export type UpdateSceneBundledScenesMutation = {
  updateSceneBundledScenes?:  {
    __typename: "SceneBundledScenes",
    id: string,
    sceneId: string,
    bundledScenesId: string,
    scene:  {
      __typename: "Scene",
      id: string,
      path: string,
      name: string,
      brief:  {
        __typename: "Brief",
        description?: string | null,
        blocks?: Array< BlockType | null > | null,
      },
      status: SceneStatus,
      type?: SceneType | null,
      format?: SceneFormat | null,
      sceneDescription?: string | null,
      includeInPromptCatalog?: boolean | null,
      viewsSupported?: Array< ViewType | null > | null,
      thumbnailPath?: string | null,
      isAnimatable?: boolean | null,
      selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
      optionsPerBlock?: Array< Array< string | null > | null > | null,
      themes?:  Array< {
        __typename: "SceneTheme",
        main?: string | null,
        sub?: string | null,
      } | null > | null,
      productSupportedSize?: number | null,
      hasBlurryBG?: boolean | null,
      bundledScenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    bundledScenes:  {
      __typename: "BundledScenes",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      scenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      packs?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      mediaType?: MediaType | null,
      status?: BundledScenesStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteSceneBundledScenesMutationVariables = {
  input: DeleteSceneBundledScenesInput,
  condition?: ModelSceneBundledScenesConditionInput | null,
};

export type DeleteSceneBundledScenesMutation = {
  deleteSceneBundledScenes?:  {
    __typename: "SceneBundledScenes",
    id: string,
    sceneId: string,
    bundledScenesId: string,
    scene:  {
      __typename: "Scene",
      id: string,
      path: string,
      name: string,
      brief:  {
        __typename: "Brief",
        description?: string | null,
        blocks?: Array< BlockType | null > | null,
      },
      status: SceneStatus,
      type?: SceneType | null,
      format?: SceneFormat | null,
      sceneDescription?: string | null,
      includeInPromptCatalog?: boolean | null,
      viewsSupported?: Array< ViewType | null > | null,
      thumbnailPath?: string | null,
      isAnimatable?: boolean | null,
      selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
      optionsPerBlock?: Array< Array< string | null > | null > | null,
      themes?:  Array< {
        __typename: "SceneTheme",
        main?: string | null,
        sub?: string | null,
      } | null > | null,
      productSupportedSize?: number | null,
      hasBlurryBG?: boolean | null,
      bundledScenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    bundledScenes:  {
      __typename: "BundledScenes",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      scenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      packs?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      mediaType?: MediaType | null,
      status?: BundledScenesStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreatePackBundledScenesMutationVariables = {
  input: CreatePackBundledScenesInput,
  condition?: ModelPackBundledScenesConditionInput | null,
};

export type CreatePackBundledScenesMutation = {
  createPackBundledScenes?:  {
    __typename: "PackBundledScenes",
    id: string,
    bundledScenesId: string,
    packId: string,
    bundledScenes:  {
      __typename: "BundledScenes",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      scenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      packs?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      mediaType?: MediaType | null,
      status?: BundledScenesStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    pack:  {
      __typename: "Pack",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      level?: number | null,
      bundledScenes?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      tags?: Array< PackTag | null > | null,
      status?: PackStatus | null,
      order?: number | null,
      exampleImages?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdatePackBundledScenesMutationVariables = {
  input: UpdatePackBundledScenesInput,
  condition?: ModelPackBundledScenesConditionInput | null,
};

export type UpdatePackBundledScenesMutation = {
  updatePackBundledScenes?:  {
    __typename: "PackBundledScenes",
    id: string,
    bundledScenesId: string,
    packId: string,
    bundledScenes:  {
      __typename: "BundledScenes",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      scenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      packs?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      mediaType?: MediaType | null,
      status?: BundledScenesStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    pack:  {
      __typename: "Pack",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      level?: number | null,
      bundledScenes?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      tags?: Array< PackTag | null > | null,
      status?: PackStatus | null,
      order?: number | null,
      exampleImages?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeletePackBundledScenesMutationVariables = {
  input: DeletePackBundledScenesInput,
  condition?: ModelPackBundledScenesConditionInput | null,
};

export type DeletePackBundledScenesMutation = {
  deletePackBundledScenes?:  {
    __typename: "PackBundledScenes",
    id: string,
    bundledScenesId: string,
    packId: string,
    bundledScenes:  {
      __typename: "BundledScenes",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      scenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      packs?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      mediaType?: MediaType | null,
      status?: BundledScenesStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    pack:  {
      __typename: "Pack",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      level?: number | null,
      bundledScenes?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      tags?: Array< PackTag | null > | null,
      status?: PackStatus | null,
      order?: number | null,
      exampleImages?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type GetProductQueryVariables = {
  id: string,
};

export type GetProductQuery = {
  getProduct?:  {
    __typename: "Product",
    id: string,
    path: string,
    name: string,
    status: ProductStatus,
    productType?: ProductType | null,
    thumbnailPath?: string | null,
    supportedViewType?: Array< ViewType | null > | null,
    industryType?: string | null,
    productFamily?: string | null,
    size?:  {
      __typename: "ProductSize",
      width?: number | null,
      height?: number | null,
      depth?: number | null,
    } | null,
    assets?:  Array< {
      __typename: "ProductAsset",
      url?: string | null,
    } | null > | null,
    dataForInvoice?:  {
      __typename: "ProductDataForInvoice",
      customProductComplexityRank?: number | null,
      priceId?: string | null,
      immersiveProductId?: string | null,
      priceValue?: number | null,
      priceCurrency?: string | null,
    } | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductsQueryVariables = {
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductsQuery = {
  listProducts?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProductSampleQueryVariables = {
  id: string,
};

export type GetProductSampleQuery = {
  getProductSample?:  {
    __typename: "ProductSample",
    id: string,
    path: string,
    name: string,
    thumbnailPath: string,
    size?:  {
      __typename: "ProductSize",
      width?: number | null,
      height?: number | null,
      depth?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListProductSamplesQueryVariables = {
  filter?: ModelProductSampleFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProductSamplesQuery = {
  listProductSamples?:  {
    __typename: "ModelProductSampleConnection",
    items:  Array< {
      __typename: "ProductSample",
      id: string,
      path: string,
      name: string,
      thumbnailPath: string,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSceneQueryVariables = {
  id: string,
};

export type GetSceneQuery = {
  getScene?:  {
    __typename: "Scene",
    id: string,
    path: string,
    name: string,
    brief:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    },
    status: SceneStatus,
    type?: SceneType | null,
    format?: SceneFormat | null,
    sceneDescription?: string | null,
    includeInPromptCatalog?: boolean | null,
    viewsSupported?: Array< ViewType | null > | null,
    thumbnailPath?: string | null,
    isAnimatable?: boolean | null,
    selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
    optionsPerBlock?: Array< Array< string | null > | null > | null,
    themes?:  Array< {
      __typename: "SceneTheme",
      main?: string | null,
      sub?: string | null,
    } | null > | null,
    productSupportedSize?: number | null,
    hasBlurryBG?: boolean | null,
    bundledScenes?:  {
      __typename: "ModelSceneBundledScenesConnection",
      items:  Array< {
        __typename: "SceneBundledScenes",
        id: string,
        sceneId: string,
        bundledScenesId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListScenesQueryVariables = {
  filter?: ModelSceneFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListScenesQuery = {
  listScenes?:  {
    __typename: "ModelSceneConnection",
    items:  Array< {
      __typename: "Scene",
      id: string,
      path: string,
      name: string,
      brief:  {
        __typename: "Brief",
        description?: string | null,
        blocks?: Array< BlockType | null > | null,
      },
      status: SceneStatus,
      type?: SceneType | null,
      format?: SceneFormat | null,
      sceneDescription?: string | null,
      includeInPromptCatalog?: boolean | null,
      viewsSupported?: Array< ViewType | null > | null,
      thumbnailPath?: string | null,
      isAnimatable?: boolean | null,
      selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
      optionsPerBlock?: Array< Array< string | null > | null > | null,
      themes?:  Array< {
        __typename: "SceneTheme",
        main?: string | null,
        sub?: string | null,
      } | null > | null,
      productSupportedSize?: number | null,
      hasBlurryBG?: boolean | null,
      bundledScenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetBundledScenesQueryVariables = {
  id: string,
};

export type GetBundledScenesQuery = {
  getBundledScenes?:  {
    __typename: "BundledScenes",
    id: string,
    name?: string | null,
    description?: string | null,
    thumbnailPath?: string | null,
    scenes?:  {
      __typename: "ModelSceneBundledScenesConnection",
      items:  Array< {
        __typename: "SceneBundledScenes",
        id: string,
        sceneId: string,
        bundledScenesId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    packs?:  {
      __typename: "ModelPackBundledScenesConnection",
      items:  Array< {
        __typename: "PackBundledScenes",
        id: string,
        bundledScenesId: string,
        packId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    mediaType?: MediaType | null,
    status?: BundledScenesStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListBundledScenesQueryVariables = {
  filter?: ModelBundledScenesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBundledScenesQuery = {
  listBundledScenes?:  {
    __typename: "ModelBundledScenesConnection",
    items:  Array< {
      __typename: "BundledScenes",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      scenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      packs?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      mediaType?: MediaType | null,
      status?: BundledScenesStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPackQueryVariables = {
  id: string,
};

export type GetPackQuery = {
  getPack?:  {
    __typename: "Pack",
    id: string,
    name?: string | null,
    description?: string | null,
    thumbnailPath?: string | null,
    level?: number | null,
    bundledScenes?:  {
      __typename: "ModelPackBundledScenesConnection",
      items:  Array< {
        __typename: "PackBundledScenes",
        id: string,
        bundledScenesId: string,
        packId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tags?: Array< PackTag | null > | null,
    status?: PackStatus | null,
    order?: number | null,
    exampleImages?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPacksQueryVariables = {
  filter?: ModelPackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPacksQuery = {
  listPacks?:  {
    __typename: "ModelPackConnection",
    items:  Array< {
      __typename: "Pack",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      level?: number | null,
      bundledScenes?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      tags?: Array< PackTag | null > | null,
      status?: PackStatus | null,
      order?: number | null,
      exampleImages?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetGeneratedPackQueryVariables = {
  id: string,
};

export type GetGeneratedPackQuery = {
  getGeneratedPack?:  {
    __typename: "GeneratedPack",
    id: string,
    pack?:  {
      __typename: "Pack",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      level?: number | null,
      bundledScenes?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      tags?: Array< PackTag | null > | null,
      status?: PackStatus | null,
      order?: number | null,
      exampleImages?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    product?:  {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    status?: GeneratedPackStatus | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    generatedPackPackId?: string | null,
    generatedPackProductId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListGeneratedPacksQueryVariables = {
  filter?: ModelGeneratedPackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListGeneratedPacksQuery = {
  listGeneratedPacks?:  {
    __typename: "ModelGeneratedPackConnection",
    items:  Array< {
      __typename: "GeneratedPack",
      id: string,
      pack?:  {
        __typename: "Pack",
        id: string,
        name?: string | null,
        description?: string | null,
        thumbnailPath?: string | null,
        level?: number | null,
        tags?: Array< PackTag | null > | null,
        status?: PackStatus | null,
        order?: number | null,
        exampleImages?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      product?:  {
        __typename: "Product",
        id: string,
        path: string,
        name: string,
        status: ProductStatus,
        productType?: ProductType | null,
        thumbnailPath?: string | null,
        supportedViewType?: Array< ViewType | null > | null,
        industryType?: string | null,
        productFamily?: string | null,
        username: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      status?: GeneratedPackStatus | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      generatedPackPackId?: string | null,
      generatedPackProductId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetTrackedProgressItemQueryVariables = {
  id: string,
};

export type GetTrackedProgressItemQuery = {
  getTrackedProgressItem?:  {
    __typename: "TrackedProgressItem",
    id: string,
    progress: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListTrackedProgressItemsQueryVariables = {
  filter?: ModelTrackedProgressItemFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListTrackedProgressItemsQuery = {
  listTrackedProgressItems?:  {
    __typename: "ModelTrackedProgressItemConnection",
    items:  Array< {
      __typename: "TrackedProgressItem",
      id: string,
      progress: number,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetStoryQueryVariables = {
  id: string,
};

export type GetStoryQuery = {
  getStory?:  {
    __typename: "Story",
    id: string,
    sceneId: string,
    scenePath: string,
    sceneName: string,
    sceneBrief:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    },
    sceneStatus: SceneStatus,
    sceneType?: SceneType | null,
    format?: SceneFormat | null,
    sceneHasBlurryBG?: boolean | null,
    selectedValuesOfBlocks?: Array< string | null > | null,
    optionsPerBlock?: Array< Array< string | null > | null > | null,
    status?: StoryStatus | null,
    similarity?: number | null,
    isGeneratedFromHistory?: boolean | null,
    reimaginesData?:  Array< {
      __typename: "ReimagineData",
      s3key?: string | null,
      type?: ReimagineType | null,
      selectedValuesOfBlocks?: Array< string | null > | null,
      reimagineInfo?:  {
        __typename: "ReimagineInfo",
        option?: string | null,
        category?: string | null,
        preset?: string | null,
      } | null,
    } | null > | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    projectStoriesId?: string | null,
  } | null,
};

export type ListStoriesQueryVariables = {
  filter?: ModelStoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListStoriesQuery = {
  listStories?:  {
    __typename: "ModelStoryConnection",
    items:  Array< {
      __typename: "Story",
      id: string,
      sceneId: string,
      scenePath: string,
      sceneName: string,
      sceneBrief:  {
        __typename: "Brief",
        description?: string | null,
        blocks?: Array< BlockType | null > | null,
      },
      sceneStatus: SceneStatus,
      sceneType?: SceneType | null,
      format?: SceneFormat | null,
      sceneHasBlurryBG?: boolean | null,
      selectedValuesOfBlocks?: Array< string | null > | null,
      optionsPerBlock?: Array< Array< string | null > | null > | null,
      status?: StoryStatus | null,
      similarity?: number | null,
      isGeneratedFromHistory?: boolean | null,
      reimaginesData?:  Array< {
        __typename: "ReimagineData",
        s3key?: string | null,
        type?: ReimagineType | null,
        selectedValuesOfBlocks?: Array< string | null > | null,
      } | null > | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      projectStoriesId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetProjectQueryVariables = {
  id: string,
};

export type GetProjectQuery = {
  getProject?:  {
    __typename: "Project",
    id: string,
    name?: string | null,
    userBrief?:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    } | null,
    selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
    stories?:  {
      __typename: "ModelStoryConnection",
      items:  Array< {
        __typename: "Story",
        id: string,
        sceneId: string,
        scenePath: string,
        sceneName: string,
        sceneStatus: SceneStatus,
        sceneType?: SceneType | null,
        format?: SceneFormat | null,
        sceneHasBlurryBG?: boolean | null,
        selectedValuesOfBlocks?: Array< string | null > | null,
        optionsPerBlock?: Array< Array< string | null > | null > | null,
        status?: StoryStatus | null,
        similarity?: number | null,
        isGeneratedFromHistory?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        projectStoriesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    containingFolder?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    type: string,
    updatedAt: string,
    status?: ProjectStatus | null,
    wizardDialogData?:  {
      __typename: "WizardDialogData",
      step_1?: string | null,
      step_2?: string | null,
    } | null,
    imagesContstructingThumbnailIds?: Array< string | null > | null,
    username: string,
    createdAt: string,
    folderProjectsId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListProjectsQueryVariables = {
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProjectsQuery = {
  listProjects?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      name?: string | null,
      userBrief?:  {
        __typename: "Brief",
        description?: string | null,
        blocks?: Array< BlockType | null > | null,
      } | null,
      selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
      stories?:  {
        __typename: "ModelStoryConnection",
        nextToken?: string | null,
      } | null,
      containingFolder?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      type: string,
      updatedAt: string,
      status?: ProjectStatus | null,
      wizardDialogData?:  {
        __typename: "WizardDialogData",
        step_1?: string | null,
        step_2?: string | null,
      } | null,
      imagesContstructingThumbnailIds?: Array< string | null > | null,
      username: string,
      createdAt: string,
      folderProjectsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetFolderQueryVariables = {
  id: string,
};

export type GetFolderQuery = {
  getFolder?:  {
    __typename: "Folder",
    id: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name?: string | null,
        selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
        type: string,
        updatedAt: string,
        status?: ProjectStatus | null,
        imagesContstructingThumbnailIds?: Array< string | null > | null,
        username: string,
        createdAt: string,
        folderProjectsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    childFolders?:  {
      __typename: "ModelFolderConnection",
      items:  Array< {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    parent?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    folderChildFoldersId?: string | null,
    folderParentId?: string | null,
  } | null,
};

export type ListFoldersQueryVariables = {
  filter?: ModelFolderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListFoldersQuery = {
  listFolders?:  {
    __typename: "ModelFolderConnection",
    items:  Array< {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSpaceQueryVariables = {
  id: string,
};

export type GetSpaceQuery = {
  getSpace?:  {
    __typename: "Space",
    id: string,
    name: string,
    rootFolder?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    spaceRootFolderId?: string | null,
    owner?: string | null,
  } | null,
};

export type ListSpacesQueryVariables = {
  filter?: ModelSpaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSpacesQuery = {
  listSpaces?:  {
    __typename: "ModelSpaceConnection",
    items:  Array< {
      __typename: "Space",
      id: string,
      name: string,
      rootFolder?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      spaceRootFolderId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetActiveSpaceQueryVariables = {
  owner: string,
};

export type GetActiveSpaceQuery = {
  getActiveSpace?:  {
    __typename: "ActiveSpace",
    space?:  {
      __typename: "Space",
      id: string,
      name: string,
      rootFolder?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      spaceRootFolderId?: string | null,
      owner?: string | null,
    } | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    activeSpaceSpaceId?: string | null,
  } | null,
};

export type ListActiveSpacesQueryVariables = {
  owner?: string | null,
  filter?: ModelActiveSpaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListActiveSpacesQuery = {
  listActiveSpaces?:  {
    __typename: "ModelActiveSpaceConnection",
    items:  Array< {
      __typename: "ActiveSpace",
      space?:  {
        __typename: "Space",
        id: string,
        name: string,
        username: string,
        createdAt: string,
        updatedAt: string,
        spaceRootFolderId?: string | null,
        owner?: string | null,
      } | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      activeSpaceSpaceId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetActiveProductQueryVariables = {
  owner: string,
};

export type GetActiveProductQuery = {
  getActiveProduct?:  {
    __typename: "ActiveProduct",
    product?:  {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    activeProductProductId?: string | null,
  } | null,
};

export type ListActiveProductsQueryVariables = {
  owner?: string | null,
  filter?: ModelActiveProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListActiveProductsQuery = {
  listActiveProducts?:  {
    __typename: "ModelActiveProductConnection",
    items:  Array< {
      __typename: "ActiveProduct",
      product?:  {
        __typename: "Product",
        id: string,
        path: string,
        name: string,
        status: ProductStatus,
        productType?: ProductType | null,
        thumbnailPath?: string | null,
        supportedViewType?: Array< ViewType | null > | null,
        industryType?: string | null,
        productFamily?: string | null,
        username: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
      activeProductProductId?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  owner: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    onboarding:  {
      __typename: "OnboardingType",
      formSteps?:  Array< {
        __typename: "FormSteps",
        id?: number | null,
        data?: string | null,
        isSubmited?: boolean | null,
      } | null > | null,
      uiSteps?:  Array< {
        __typename: "UISteps",
        id?: number | null,
        completedStepTypes?: Array< UIStepType | null > | null,
        isSubmited?: boolean | null,
      } | null > | null,
    },
    roles?:  Array< {
      __typename: "UserAttribute",
      id?: number | null,
      value?: string | null,
    } | null > | null,
    goals?:  Array< {
      __typename: "UserAttribute",
      id?: number | null,
      value?: string | null,
    } | null > | null,
    stripeId?: string | null,
    limitation?:  {
      __typename: "LimitationType",
      generatedStoriesInLimitPeriod?: number | null,
      createdProjectsInLimitPeriod?: number | null,
      createdCustomProductsInLimitPeriod?: number | null,
      createdStandardProductsInLimitPeriod?: number | null,
      generatedReimagines?: number | null,
      downloadedImages?: number | null,
      downloadedVideos?: number | null,
      generatedPacks?: number | null,
      subscriptionStartDate?: string | null,
      limitResetDate?: string | null,
      projectLimit?: number | null,
      storiesLimit?: number | null,
      storiesLimitPeriod?: string | null,
      customProducts?: number | null,
      standardProducts?: number | null,
      images?: number | null,
      videos?: number | null,
      packsLimit?: number | null,
    } | null,
    seenSceneIds?: Array< string | null > | null,
    isSubscriptionCanceled?: boolean | null,
    isReimagineClicked?: boolean | null,
    trialSubscriptionStatus?: TrialSubscriptionStatus | null,
    location?:  {
      __typename: "Location",
      country?: string | null,
      countryName?: string | null,
      countryCode?: string | null,
      countryCodeISO3?: string | null,
      currency?: string | null,
      currencyName?: string | null,
      languages?: string | null,
    } | null,
    paymentMethodStatus?: PaymentMethodStatus | null,
    seenGeneratedPackIds?: Array< string | null > | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUsersQueryVariables = {
  owner?: string | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      roles?:  Array< {
        __typename: "UserAttribute",
        id?: number | null,
        value?: string | null,
      } | null > | null,
      goals?:  Array< {
        __typename: "UserAttribute",
        id?: number | null,
        value?: string | null,
      } | null > | null,
      stripeId?: string | null,
      limitation?:  {
        __typename: "LimitationType",
        generatedStoriesInLimitPeriod?: number | null,
        createdProjectsInLimitPeriod?: number | null,
        createdCustomProductsInLimitPeriod?: number | null,
        createdStandardProductsInLimitPeriod?: number | null,
        generatedReimagines?: number | null,
        downloadedImages?: number | null,
        downloadedVideos?: number | null,
        generatedPacks?: number | null,
        subscriptionStartDate?: string | null,
        limitResetDate?: string | null,
        projectLimit?: number | null,
        storiesLimit?: number | null,
        storiesLimitPeriod?: string | null,
        customProducts?: number | null,
        standardProducts?: number | null,
        images?: number | null,
        videos?: number | null,
        packsLimit?: number | null,
      } | null,
      seenSceneIds?: Array< string | null > | null,
      isSubscriptionCanceled?: boolean | null,
      isReimagineClicked?: boolean | null,
      trialSubscriptionStatus?: TrialSubscriptionStatus | null,
      location?:  {
        __typename: "Location",
        country?: string | null,
        countryName?: string | null,
        countryCode?: string | null,
        countryCodeISO3?: string | null,
        currency?: string | null,
        currencyName?: string | null,
        languages?: string | null,
      } | null,
      paymentMethodStatus?: PaymentMethodStatus | null,
      seenGeneratedPackIds?: Array< string | null > | null,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPromptCatalogQueryVariables = {
  id: string,
};

export type GetPromptCatalogQuery = {
  getPromptCatalog?:  {
    __typename: "PromptCatalog",
    id: string,
    colors?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    elements?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    descriptions?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPromptCatalogsQueryVariables = {
  filter?: ModelPromptCatalogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPromptCatalogsQuery = {
  listPromptCatalogs?:  {
    __typename: "ModelPromptCatalogConnection",
    items:  Array< {
      __typename: "PromptCatalog",
      id: string,
      colors?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
      elements?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
      descriptions?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPromptHistoryQueryVariables = {
  id: string,
};

export type GetPromptHistoryQuery = {
  getPromptHistory?:  {
    __typename: "PromptHistory",
    description?: string | null,
    score?: Array< number | null > | null,
    username: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPromptHistoriesQueryVariables = {
  filter?: ModelPromptHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPromptHistoriesQuery = {
  listPromptHistories?:  {
    __typename: "ModelPromptHistoryConnection",
    items:  Array< {
      __typename: "PromptHistory",
      description?: string | null,
      score?: Array< number | null > | null,
      username: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetServiceQueryVariables = {
  id: string,
};

export type GetServiceQuery = {
  getService?:  {
    __typename: "Service",
    id: string,
    name: string,
    poorAvailabilityDetectedTime?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListServicesQueryVariables = {
  filter?: ModelServiceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListServicesQuery = {
  listServices?:  {
    __typename: "ModelServiceConnection",
    items:  Array< {
      __typename: "Service",
      id: string,
      name: string,
      poorAvailabilityDetectedTime?: string | null,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetSceneBundledScenesQueryVariables = {
  id: string,
};

export type GetSceneBundledScenesQuery = {
  getSceneBundledScenes?:  {
    __typename: "SceneBundledScenes",
    id: string,
    sceneId: string,
    bundledScenesId: string,
    scene:  {
      __typename: "Scene",
      id: string,
      path: string,
      name: string,
      brief:  {
        __typename: "Brief",
        description?: string | null,
        blocks?: Array< BlockType | null > | null,
      },
      status: SceneStatus,
      type?: SceneType | null,
      format?: SceneFormat | null,
      sceneDescription?: string | null,
      includeInPromptCatalog?: boolean | null,
      viewsSupported?: Array< ViewType | null > | null,
      thumbnailPath?: string | null,
      isAnimatable?: boolean | null,
      selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
      optionsPerBlock?: Array< Array< string | null > | null > | null,
      themes?:  Array< {
        __typename: "SceneTheme",
        main?: string | null,
        sub?: string | null,
      } | null > | null,
      productSupportedSize?: number | null,
      hasBlurryBG?: boolean | null,
      bundledScenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    bundledScenes:  {
      __typename: "BundledScenes",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      scenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      packs?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      mediaType?: MediaType | null,
      status?: BundledScenesStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListSceneBundledScenesQueryVariables = {
  filter?: ModelSceneBundledScenesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSceneBundledScenesQuery = {
  listSceneBundledScenes?:  {
    __typename: "ModelSceneBundledScenesConnection",
    items:  Array< {
      __typename: "SceneBundledScenes",
      id: string,
      sceneId: string,
      bundledScenesId: string,
      scene:  {
        __typename: "Scene",
        id: string,
        path: string,
        name: string,
        status: SceneStatus,
        type?: SceneType | null,
        format?: SceneFormat | null,
        sceneDescription?: string | null,
        includeInPromptCatalog?: boolean | null,
        viewsSupported?: Array< ViewType | null > | null,
        thumbnailPath?: string | null,
        isAnimatable?: boolean | null,
        selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
        optionsPerBlock?: Array< Array< string | null > | null > | null,
        productSupportedSize?: number | null,
        hasBlurryBG?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      bundledScenes:  {
        __typename: "BundledScenes",
        id: string,
        name?: string | null,
        description?: string | null,
        thumbnailPath?: string | null,
        mediaType?: MediaType | null,
        status?: BundledScenesStatus | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetPackBundledScenesQueryVariables = {
  id: string,
};

export type GetPackBundledScenesQuery = {
  getPackBundledScenes?:  {
    __typename: "PackBundledScenes",
    id: string,
    bundledScenesId: string,
    packId: string,
    bundledScenes:  {
      __typename: "BundledScenes",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      scenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      packs?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      mediaType?: MediaType | null,
      status?: BundledScenesStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    pack:  {
      __typename: "Pack",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      level?: number | null,
      bundledScenes?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      tags?: Array< PackTag | null > | null,
      status?: PackStatus | null,
      order?: number | null,
      exampleImages?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListPackBundledScenesQueryVariables = {
  filter?: ModelPackBundledScenesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPackBundledScenesQuery = {
  listPackBundledScenes?:  {
    __typename: "ModelPackBundledScenesConnection",
    items:  Array< {
      __typename: "PackBundledScenes",
      id: string,
      bundledScenesId: string,
      packId: string,
      bundledScenes:  {
        __typename: "BundledScenes",
        id: string,
        name?: string | null,
        description?: string | null,
        thumbnailPath?: string | null,
        mediaType?: MediaType | null,
        status?: BundledScenesStatus | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      pack:  {
        __typename: "Pack",
        id: string,
        name?: string | null,
        description?: string | null,
        thumbnailPath?: string | null,
        level?: number | null,
        tags?: Array< PackTag | null > | null,
        status?: PackStatus | null,
        order?: number | null,
        exampleImages?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProductByUsernameAndStatusQueryVariables = {
  username: string,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProductFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProductByUsernameAndStatusQuery = {
  productByUsernameAndStatus?:  {
    __typename: "ModelProductConnection",
    items:  Array< {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GeneratedPackByUsernameQueryVariables = {
  username: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelGeneratedPackFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GeneratedPackByUsernameQuery = {
  generatedPackByUsername?:  {
    __typename: "ModelGeneratedPackConnection",
    items:  Array< {
      __typename: "GeneratedPack",
      id: string,
      pack?:  {
        __typename: "Pack",
        id: string,
        name?: string | null,
        description?: string | null,
        thumbnailPath?: string | null,
        level?: number | null,
        tags?: Array< PackTag | null > | null,
        status?: PackStatus | null,
        order?: number | null,
        exampleImages?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      product?:  {
        __typename: "Product",
        id: string,
        path: string,
        name: string,
        status: ProductStatus,
        productType?: ProductType | null,
        thumbnailPath?: string | null,
        supportedViewType?: Array< ViewType | null > | null,
        industryType?: string | null,
        productFamily?: string | null,
        username: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      status?: GeneratedPackStatus | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      generatedPackPackId?: string | null,
      generatedPackProductId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectsByLastUpdatedQueryVariables = {
  type: string,
  updatedAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectsByLastUpdatedQuery = {
  projectsByLastUpdated?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      name?: string | null,
      userBrief?:  {
        __typename: "Brief",
        description?: string | null,
        blocks?: Array< BlockType | null > | null,
      } | null,
      selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
      stories?:  {
        __typename: "ModelStoryConnection",
        nextToken?: string | null,
      } | null,
      containingFolder?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      type: string,
      updatedAt: string,
      status?: ProjectStatus | null,
      wizardDialogData?:  {
        __typename: "WizardDialogData",
        step_1?: string | null,
        step_2?: string | null,
      } | null,
      imagesContstructingThumbnailIds?: Array< string | null > | null,
      username: string,
      createdAt: string,
      folderProjectsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type ProjectByUsernameQueryVariables = {
  username: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelProjectFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ProjectByUsernameQuery = {
  projectByUsername?:  {
    __typename: "ModelProjectConnection",
    items:  Array< {
      __typename: "Project",
      id: string,
      name?: string | null,
      userBrief?:  {
        __typename: "Brief",
        description?: string | null,
        blocks?: Array< BlockType | null > | null,
      } | null,
      selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
      stories?:  {
        __typename: "ModelStoryConnection",
        nextToken?: string | null,
      } | null,
      containingFolder?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      type: string,
      updatedAt: string,
      status?: ProjectStatus | null,
      wizardDialogData?:  {
        __typename: "WizardDialogData",
        step_1?: string | null,
        step_2?: string | null,
      } | null,
      imagesContstructingThumbnailIds?: Array< string | null > | null,
      username: string,
      createdAt: string,
      folderProjectsId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SpaceByUsernameQueryVariables = {
  username: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSpaceFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SpaceByUsernameQuery = {
  spaceByUsername?:  {
    __typename: "ModelSpaceConnection",
    items:  Array< {
      __typename: "Space",
      id: string,
      name: string,
      rootFolder?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      spaceRootFolderId?: string | null,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PromptHistoryByUsernameQueryVariables = {
  username: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPromptHistoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PromptHistoryByUsernameQuery = {
  promptHistoryByUsername?:  {
    __typename: "ModelPromptHistoryConnection",
    items:  Array< {
      __typename: "PromptHistory",
      description?: string | null,
      score?: Array< number | null > | null,
      username: string,
      id: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SceneBundledScenesBySceneIdQueryVariables = {
  sceneId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSceneBundledScenesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SceneBundledScenesBySceneIdQuery = {
  sceneBundledScenesBySceneId?:  {
    __typename: "ModelSceneBundledScenesConnection",
    items:  Array< {
      __typename: "SceneBundledScenes",
      id: string,
      sceneId: string,
      bundledScenesId: string,
      scene:  {
        __typename: "Scene",
        id: string,
        path: string,
        name: string,
        status: SceneStatus,
        type?: SceneType | null,
        format?: SceneFormat | null,
        sceneDescription?: string | null,
        includeInPromptCatalog?: boolean | null,
        viewsSupported?: Array< ViewType | null > | null,
        thumbnailPath?: string | null,
        isAnimatable?: boolean | null,
        selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
        optionsPerBlock?: Array< Array< string | null > | null > | null,
        productSupportedSize?: number | null,
        hasBlurryBG?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      bundledScenes:  {
        __typename: "BundledScenes",
        id: string,
        name?: string | null,
        description?: string | null,
        thumbnailPath?: string | null,
        mediaType?: MediaType | null,
        status?: BundledScenesStatus | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type SceneBundledScenesByBundledScenesIdQueryVariables = {
  bundledScenesId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelSceneBundledScenesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type SceneBundledScenesByBundledScenesIdQuery = {
  sceneBundledScenesByBundledScenesId?:  {
    __typename: "ModelSceneBundledScenesConnection",
    items:  Array< {
      __typename: "SceneBundledScenes",
      id: string,
      sceneId: string,
      bundledScenesId: string,
      scene:  {
        __typename: "Scene",
        id: string,
        path: string,
        name: string,
        status: SceneStatus,
        type?: SceneType | null,
        format?: SceneFormat | null,
        sceneDescription?: string | null,
        includeInPromptCatalog?: boolean | null,
        viewsSupported?: Array< ViewType | null > | null,
        thumbnailPath?: string | null,
        isAnimatable?: boolean | null,
        selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
        optionsPerBlock?: Array< Array< string | null > | null > | null,
        productSupportedSize?: number | null,
        hasBlurryBG?: boolean | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      bundledScenes:  {
        __typename: "BundledScenes",
        id: string,
        name?: string | null,
        description?: string | null,
        thumbnailPath?: string | null,
        mediaType?: MediaType | null,
        status?: BundledScenesStatus | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PackBundledScenesByBundledScenesIdQueryVariables = {
  bundledScenesId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPackBundledScenesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PackBundledScenesByBundledScenesIdQuery = {
  packBundledScenesByBundledScenesId?:  {
    __typename: "ModelPackBundledScenesConnection",
    items:  Array< {
      __typename: "PackBundledScenes",
      id: string,
      bundledScenesId: string,
      packId: string,
      bundledScenes:  {
        __typename: "BundledScenes",
        id: string,
        name?: string | null,
        description?: string | null,
        thumbnailPath?: string | null,
        mediaType?: MediaType | null,
        status?: BundledScenesStatus | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      pack:  {
        __typename: "Pack",
        id: string,
        name?: string | null,
        description?: string | null,
        thumbnailPath?: string | null,
        level?: number | null,
        tags?: Array< PackTag | null > | null,
        status?: PackStatus | null,
        order?: number | null,
        exampleImages?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type PackBundledScenesByPackIdQueryVariables = {
  packId: string,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelPackBundledScenesFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type PackBundledScenesByPackIdQuery = {
  packBundledScenesByPackId?:  {
    __typename: "ModelPackBundledScenesConnection",
    items:  Array< {
      __typename: "PackBundledScenes",
      id: string,
      bundledScenesId: string,
      packId: string,
      bundledScenes:  {
        __typename: "BundledScenes",
        id: string,
        name?: string | null,
        description?: string | null,
        thumbnailPath?: string | null,
        mediaType?: MediaType | null,
        status?: BundledScenesStatus | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      pack:  {
        __typename: "Pack",
        id: string,
        name?: string | null,
        description?: string | null,
        thumbnailPath?: string | null,
        level?: number | null,
        tags?: Array< PackTag | null > | null,
        status?: PackStatus | null,
        order?: number | null,
        exampleImages?: Array< string | null > | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnCreateProductSubscription = {
  onCreateProduct?:  {
    __typename: "Product",
    id: string,
    path: string,
    name: string,
    status: ProductStatus,
    productType?: ProductType | null,
    thumbnailPath?: string | null,
    supportedViewType?: Array< ViewType | null > | null,
    industryType?: string | null,
    productFamily?: string | null,
    size?:  {
      __typename: "ProductSize",
      width?: number | null,
      height?: number | null,
      depth?: number | null,
    } | null,
    assets?:  Array< {
      __typename: "ProductAsset",
      url?: string | null,
    } | null > | null,
    dataForInvoice?:  {
      __typename: "ProductDataForInvoice",
      customProductComplexityRank?: number | null,
      priceId?: string | null,
      immersiveProductId?: string | null,
      priceValue?: number | null,
      priceCurrency?: string | null,
    } | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProductSubscription = {
  onUpdateProduct?:  {
    __typename: "Product",
    id: string,
    path: string,
    name: string,
    status: ProductStatus,
    productType?: ProductType | null,
    thumbnailPath?: string | null,
    supportedViewType?: Array< ViewType | null > | null,
    industryType?: string | null,
    productFamily?: string | null,
    size?:  {
      __typename: "ProductSize",
      width?: number | null,
      height?: number | null,
      depth?: number | null,
    } | null,
    assets?:  Array< {
      __typename: "ProductAsset",
      url?: string | null,
    } | null > | null,
    dataForInvoice?:  {
      __typename: "ProductDataForInvoice",
      customProductComplexityRank?: number | null,
      priceId?: string | null,
      immersiveProductId?: string | null,
      priceValue?: number | null,
      priceCurrency?: string | null,
    } | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductSubscriptionVariables = {
  filter?: ModelSubscriptionProductFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProductSubscription = {
  onDeleteProduct?:  {
    __typename: "Product",
    id: string,
    path: string,
    name: string,
    status: ProductStatus,
    productType?: ProductType | null,
    thumbnailPath?: string | null,
    supportedViewType?: Array< ViewType | null > | null,
    industryType?: string | null,
    productFamily?: string | null,
    size?:  {
      __typename: "ProductSize",
      width?: number | null,
      height?: number | null,
      depth?: number | null,
    } | null,
    assets?:  Array< {
      __typename: "ProductAsset",
      url?: string | null,
    } | null > | null,
    dataForInvoice?:  {
      __typename: "ProductDataForInvoice",
      customProductComplexityRank?: number | null,
      priceId?: string | null,
      immersiveProductId?: string | null,
      priceValue?: number | null,
      priceCurrency?: string | null,
    } | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateProductSampleSubscriptionVariables = {
  filter?: ModelSubscriptionProductSampleFilterInput | null,
};

export type OnCreateProductSampleSubscription = {
  onCreateProductSample?:  {
    __typename: "ProductSample",
    id: string,
    path: string,
    name: string,
    thumbnailPath: string,
    size?:  {
      __typename: "ProductSize",
      width?: number | null,
      height?: number | null,
      depth?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateProductSampleSubscriptionVariables = {
  filter?: ModelSubscriptionProductSampleFilterInput | null,
};

export type OnUpdateProductSampleSubscription = {
  onUpdateProductSample?:  {
    __typename: "ProductSample",
    id: string,
    path: string,
    name: string,
    thumbnailPath: string,
    size?:  {
      __typename: "ProductSize",
      width?: number | null,
      height?: number | null,
      depth?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteProductSampleSubscriptionVariables = {
  filter?: ModelSubscriptionProductSampleFilterInput | null,
};

export type OnDeleteProductSampleSubscription = {
  onDeleteProductSample?:  {
    __typename: "ProductSample",
    id: string,
    path: string,
    name: string,
    thumbnailPath: string,
    size?:  {
      __typename: "ProductSize",
      width?: number | null,
      height?: number | null,
      depth?: number | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateSceneSubscriptionVariables = {
  filter?: ModelSubscriptionSceneFilterInput | null,
};

export type OnCreateSceneSubscription = {
  onCreateScene?:  {
    __typename: "Scene",
    id: string,
    path: string,
    name: string,
    brief:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    },
    status: SceneStatus,
    type?: SceneType | null,
    format?: SceneFormat | null,
    sceneDescription?: string | null,
    includeInPromptCatalog?: boolean | null,
    viewsSupported?: Array< ViewType | null > | null,
    thumbnailPath?: string | null,
    isAnimatable?: boolean | null,
    selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
    optionsPerBlock?: Array< Array< string | null > | null > | null,
    themes?:  Array< {
      __typename: "SceneTheme",
      main?: string | null,
      sub?: string | null,
    } | null > | null,
    productSupportedSize?: number | null,
    hasBlurryBG?: boolean | null,
    bundledScenes?:  {
      __typename: "ModelSceneBundledScenesConnection",
      items:  Array< {
        __typename: "SceneBundledScenes",
        id: string,
        sceneId: string,
        bundledScenesId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateSceneSubscriptionVariables = {
  filter?: ModelSubscriptionSceneFilterInput | null,
};

export type OnUpdateSceneSubscription = {
  onUpdateScene?:  {
    __typename: "Scene",
    id: string,
    path: string,
    name: string,
    brief:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    },
    status: SceneStatus,
    type?: SceneType | null,
    format?: SceneFormat | null,
    sceneDescription?: string | null,
    includeInPromptCatalog?: boolean | null,
    viewsSupported?: Array< ViewType | null > | null,
    thumbnailPath?: string | null,
    isAnimatable?: boolean | null,
    selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
    optionsPerBlock?: Array< Array< string | null > | null > | null,
    themes?:  Array< {
      __typename: "SceneTheme",
      main?: string | null,
      sub?: string | null,
    } | null > | null,
    productSupportedSize?: number | null,
    hasBlurryBG?: boolean | null,
    bundledScenes?:  {
      __typename: "ModelSceneBundledScenesConnection",
      items:  Array< {
        __typename: "SceneBundledScenes",
        id: string,
        sceneId: string,
        bundledScenesId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteSceneSubscriptionVariables = {
  filter?: ModelSubscriptionSceneFilterInput | null,
};

export type OnDeleteSceneSubscription = {
  onDeleteScene?:  {
    __typename: "Scene",
    id: string,
    path: string,
    name: string,
    brief:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    },
    status: SceneStatus,
    type?: SceneType | null,
    format?: SceneFormat | null,
    sceneDescription?: string | null,
    includeInPromptCatalog?: boolean | null,
    viewsSupported?: Array< ViewType | null > | null,
    thumbnailPath?: string | null,
    isAnimatable?: boolean | null,
    selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
    optionsPerBlock?: Array< Array< string | null > | null > | null,
    themes?:  Array< {
      __typename: "SceneTheme",
      main?: string | null,
      sub?: string | null,
    } | null > | null,
    productSupportedSize?: number | null,
    hasBlurryBG?: boolean | null,
    bundledScenes?:  {
      __typename: "ModelSceneBundledScenesConnection",
      items:  Array< {
        __typename: "SceneBundledScenes",
        id: string,
        sceneId: string,
        bundledScenesId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateBundledScenesSubscriptionVariables = {
  filter?: ModelSubscriptionBundledScenesFilterInput | null,
};

export type OnCreateBundledScenesSubscription = {
  onCreateBundledScenes?:  {
    __typename: "BundledScenes",
    id: string,
    name?: string | null,
    description?: string | null,
    thumbnailPath?: string | null,
    scenes?:  {
      __typename: "ModelSceneBundledScenesConnection",
      items:  Array< {
        __typename: "SceneBundledScenes",
        id: string,
        sceneId: string,
        bundledScenesId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    packs?:  {
      __typename: "ModelPackBundledScenesConnection",
      items:  Array< {
        __typename: "PackBundledScenes",
        id: string,
        bundledScenesId: string,
        packId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    mediaType?: MediaType | null,
    status?: BundledScenesStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateBundledScenesSubscriptionVariables = {
  filter?: ModelSubscriptionBundledScenesFilterInput | null,
};

export type OnUpdateBundledScenesSubscription = {
  onUpdateBundledScenes?:  {
    __typename: "BundledScenes",
    id: string,
    name?: string | null,
    description?: string | null,
    thumbnailPath?: string | null,
    scenes?:  {
      __typename: "ModelSceneBundledScenesConnection",
      items:  Array< {
        __typename: "SceneBundledScenes",
        id: string,
        sceneId: string,
        bundledScenesId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    packs?:  {
      __typename: "ModelPackBundledScenesConnection",
      items:  Array< {
        __typename: "PackBundledScenes",
        id: string,
        bundledScenesId: string,
        packId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    mediaType?: MediaType | null,
    status?: BundledScenesStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteBundledScenesSubscriptionVariables = {
  filter?: ModelSubscriptionBundledScenesFilterInput | null,
};

export type OnDeleteBundledScenesSubscription = {
  onDeleteBundledScenes?:  {
    __typename: "BundledScenes",
    id: string,
    name?: string | null,
    description?: string | null,
    thumbnailPath?: string | null,
    scenes?:  {
      __typename: "ModelSceneBundledScenesConnection",
      items:  Array< {
        __typename: "SceneBundledScenes",
        id: string,
        sceneId: string,
        bundledScenesId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    packs?:  {
      __typename: "ModelPackBundledScenesConnection",
      items:  Array< {
        __typename: "PackBundledScenes",
        id: string,
        bundledScenesId: string,
        packId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    mediaType?: MediaType | null,
    status?: BundledScenesStatus | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreatePackSubscriptionVariables = {
  filter?: ModelSubscriptionPackFilterInput | null,
};

export type OnCreatePackSubscription = {
  onCreatePack?:  {
    __typename: "Pack",
    id: string,
    name?: string | null,
    description?: string | null,
    thumbnailPath?: string | null,
    level?: number | null,
    bundledScenes?:  {
      __typename: "ModelPackBundledScenesConnection",
      items:  Array< {
        __typename: "PackBundledScenes",
        id: string,
        bundledScenesId: string,
        packId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tags?: Array< PackTag | null > | null,
    status?: PackStatus | null,
    order?: number | null,
    exampleImages?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePackSubscriptionVariables = {
  filter?: ModelSubscriptionPackFilterInput | null,
};

export type OnUpdatePackSubscription = {
  onUpdatePack?:  {
    __typename: "Pack",
    id: string,
    name?: string | null,
    description?: string | null,
    thumbnailPath?: string | null,
    level?: number | null,
    bundledScenes?:  {
      __typename: "ModelPackBundledScenesConnection",
      items:  Array< {
        __typename: "PackBundledScenes",
        id: string,
        bundledScenesId: string,
        packId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tags?: Array< PackTag | null > | null,
    status?: PackStatus | null,
    order?: number | null,
    exampleImages?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePackSubscriptionVariables = {
  filter?: ModelSubscriptionPackFilterInput | null,
};

export type OnDeletePackSubscription = {
  onDeletePack?:  {
    __typename: "Pack",
    id: string,
    name?: string | null,
    description?: string | null,
    thumbnailPath?: string | null,
    level?: number | null,
    bundledScenes?:  {
      __typename: "ModelPackBundledScenesConnection",
      items:  Array< {
        __typename: "PackBundledScenes",
        id: string,
        bundledScenesId: string,
        packId: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    tags?: Array< PackTag | null > | null,
    status?: PackStatus | null,
    order?: number | null,
    exampleImages?: Array< string | null > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateGeneratedPackSubscriptionVariables = {
  filter?: ModelSubscriptionGeneratedPackFilterInput | null,
  owner?: string | null,
};

export type OnCreateGeneratedPackSubscription = {
  onCreateGeneratedPack?:  {
    __typename: "GeneratedPack",
    id: string,
    pack?:  {
      __typename: "Pack",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      level?: number | null,
      bundledScenes?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      tags?: Array< PackTag | null > | null,
      status?: PackStatus | null,
      order?: number | null,
      exampleImages?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    product?:  {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    status?: GeneratedPackStatus | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    generatedPackPackId?: string | null,
    generatedPackProductId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateGeneratedPackSubscriptionVariables = {
  filter?: ModelSubscriptionGeneratedPackFilterInput | null,
  owner?: string | null,
};

export type OnUpdateGeneratedPackSubscription = {
  onUpdateGeneratedPack?:  {
    __typename: "GeneratedPack",
    id: string,
    pack?:  {
      __typename: "Pack",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      level?: number | null,
      bundledScenes?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      tags?: Array< PackTag | null > | null,
      status?: PackStatus | null,
      order?: number | null,
      exampleImages?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    product?:  {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    status?: GeneratedPackStatus | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    generatedPackPackId?: string | null,
    generatedPackProductId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteGeneratedPackSubscriptionVariables = {
  filter?: ModelSubscriptionGeneratedPackFilterInput | null,
  owner?: string | null,
};

export type OnDeleteGeneratedPackSubscription = {
  onDeleteGeneratedPack?:  {
    __typename: "GeneratedPack",
    id: string,
    pack?:  {
      __typename: "Pack",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      level?: number | null,
      bundledScenes?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      tags?: Array< PackTag | null > | null,
      status?: PackStatus | null,
      order?: number | null,
      exampleImages?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    product?:  {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    status?: GeneratedPackStatus | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    generatedPackPackId?: string | null,
    generatedPackProductId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateTrackedProgressItemSubscriptionVariables = {
  filter?: ModelSubscriptionTrackedProgressItemFilterInput | null,
};

export type OnCreateTrackedProgressItemSubscription = {
  onCreateTrackedProgressItem?:  {
    __typename: "TrackedProgressItem",
    id: string,
    progress: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateTrackedProgressItemSubscriptionVariables = {
  filter?: ModelSubscriptionTrackedProgressItemFilterInput | null,
};

export type OnUpdateTrackedProgressItemSubscription = {
  onUpdateTrackedProgressItem?:  {
    __typename: "TrackedProgressItem",
    id: string,
    progress: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteTrackedProgressItemSubscriptionVariables = {
  filter?: ModelSubscriptionTrackedProgressItemFilterInput | null,
};

export type OnDeleteTrackedProgressItemSubscription = {
  onDeleteTrackedProgressItem?:  {
    __typename: "TrackedProgressItem",
    id: string,
    progress: number,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateStorySubscriptionVariables = {
  filter?: ModelSubscriptionStoryFilterInput | null,
  owner?: string | null,
};

export type OnCreateStorySubscription = {
  onCreateStory?:  {
    __typename: "Story",
    id: string,
    sceneId: string,
    scenePath: string,
    sceneName: string,
    sceneBrief:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    },
    sceneStatus: SceneStatus,
    sceneType?: SceneType | null,
    format?: SceneFormat | null,
    sceneHasBlurryBG?: boolean | null,
    selectedValuesOfBlocks?: Array< string | null > | null,
    optionsPerBlock?: Array< Array< string | null > | null > | null,
    status?: StoryStatus | null,
    similarity?: number | null,
    isGeneratedFromHistory?: boolean | null,
    reimaginesData?:  Array< {
      __typename: "ReimagineData",
      s3key?: string | null,
      type?: ReimagineType | null,
      selectedValuesOfBlocks?: Array< string | null > | null,
      reimagineInfo?:  {
        __typename: "ReimagineInfo",
        option?: string | null,
        category?: string | null,
        preset?: string | null,
      } | null,
    } | null > | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    projectStoriesId?: string | null,
  } | null,
};

export type OnUpdateStorySubscriptionVariables = {
  filter?: ModelSubscriptionStoryFilterInput | null,
  owner?: string | null,
};

export type OnUpdateStorySubscription = {
  onUpdateStory?:  {
    __typename: "Story",
    id: string,
    sceneId: string,
    scenePath: string,
    sceneName: string,
    sceneBrief:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    },
    sceneStatus: SceneStatus,
    sceneType?: SceneType | null,
    format?: SceneFormat | null,
    sceneHasBlurryBG?: boolean | null,
    selectedValuesOfBlocks?: Array< string | null > | null,
    optionsPerBlock?: Array< Array< string | null > | null > | null,
    status?: StoryStatus | null,
    similarity?: number | null,
    isGeneratedFromHistory?: boolean | null,
    reimaginesData?:  Array< {
      __typename: "ReimagineData",
      s3key?: string | null,
      type?: ReimagineType | null,
      selectedValuesOfBlocks?: Array< string | null > | null,
      reimagineInfo?:  {
        __typename: "ReimagineInfo",
        option?: string | null,
        category?: string | null,
        preset?: string | null,
      } | null,
    } | null > | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    projectStoriesId?: string | null,
  } | null,
};

export type OnDeleteStorySubscriptionVariables = {
  filter?: ModelSubscriptionStoryFilterInput | null,
  owner?: string | null,
};

export type OnDeleteStorySubscription = {
  onDeleteStory?:  {
    __typename: "Story",
    id: string,
    sceneId: string,
    scenePath: string,
    sceneName: string,
    sceneBrief:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    },
    sceneStatus: SceneStatus,
    sceneType?: SceneType | null,
    format?: SceneFormat | null,
    sceneHasBlurryBG?: boolean | null,
    selectedValuesOfBlocks?: Array< string | null > | null,
    optionsPerBlock?: Array< Array< string | null > | null > | null,
    status?: StoryStatus | null,
    similarity?: number | null,
    isGeneratedFromHistory?: boolean | null,
    reimaginesData?:  Array< {
      __typename: "ReimagineData",
      s3key?: string | null,
      type?: ReimagineType | null,
      selectedValuesOfBlocks?: Array< string | null > | null,
      reimagineInfo?:  {
        __typename: "ReimagineInfo",
        option?: string | null,
        category?: string | null,
        preset?: string | null,
      } | null,
    } | null > | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    projectStoriesId?: string | null,
  } | null,
};

export type OnCreateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
  owner?: string | null,
};

export type OnCreateProjectSubscription = {
  onCreateProject?:  {
    __typename: "Project",
    id: string,
    name?: string | null,
    userBrief?:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    } | null,
    selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
    stories?:  {
      __typename: "ModelStoryConnection",
      items:  Array< {
        __typename: "Story",
        id: string,
        sceneId: string,
        scenePath: string,
        sceneName: string,
        sceneStatus: SceneStatus,
        sceneType?: SceneType | null,
        format?: SceneFormat | null,
        sceneHasBlurryBG?: boolean | null,
        selectedValuesOfBlocks?: Array< string | null > | null,
        optionsPerBlock?: Array< Array< string | null > | null > | null,
        status?: StoryStatus | null,
        similarity?: number | null,
        isGeneratedFromHistory?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        projectStoriesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    containingFolder?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    type: string,
    updatedAt: string,
    status?: ProjectStatus | null,
    wizardDialogData?:  {
      __typename: "WizardDialogData",
      step_1?: string | null,
      step_2?: string | null,
    } | null,
    imagesContstructingThumbnailIds?: Array< string | null > | null,
    username: string,
    createdAt: string,
    folderProjectsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
  owner?: string | null,
};

export type OnUpdateProjectSubscription = {
  onUpdateProject?:  {
    __typename: "Project",
    id: string,
    name?: string | null,
    userBrief?:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    } | null,
    selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
    stories?:  {
      __typename: "ModelStoryConnection",
      items:  Array< {
        __typename: "Story",
        id: string,
        sceneId: string,
        scenePath: string,
        sceneName: string,
        sceneStatus: SceneStatus,
        sceneType?: SceneType | null,
        format?: SceneFormat | null,
        sceneHasBlurryBG?: boolean | null,
        selectedValuesOfBlocks?: Array< string | null > | null,
        optionsPerBlock?: Array< Array< string | null > | null > | null,
        status?: StoryStatus | null,
        similarity?: number | null,
        isGeneratedFromHistory?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        projectStoriesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    containingFolder?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    type: string,
    updatedAt: string,
    status?: ProjectStatus | null,
    wizardDialogData?:  {
      __typename: "WizardDialogData",
      step_1?: string | null,
      step_2?: string | null,
    } | null,
    imagesContstructingThumbnailIds?: Array< string | null > | null,
    username: string,
    createdAt: string,
    folderProjectsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteProjectSubscriptionVariables = {
  filter?: ModelSubscriptionProjectFilterInput | null,
  owner?: string | null,
};

export type OnDeleteProjectSubscription = {
  onDeleteProject?:  {
    __typename: "Project",
    id: string,
    name?: string | null,
    userBrief?:  {
      __typename: "Brief",
      description?: string | null,
      blocks?: Array< BlockType | null > | null,
    } | null,
    selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
    stories?:  {
      __typename: "ModelStoryConnection",
      items:  Array< {
        __typename: "Story",
        id: string,
        sceneId: string,
        scenePath: string,
        sceneName: string,
        sceneStatus: SceneStatus,
        sceneType?: SceneType | null,
        format?: SceneFormat | null,
        sceneHasBlurryBG?: boolean | null,
        selectedValuesOfBlocks?: Array< string | null > | null,
        optionsPerBlock?: Array< Array< string | null > | null > | null,
        status?: StoryStatus | null,
        similarity?: number | null,
        isGeneratedFromHistory?: boolean | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        projectStoriesId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    containingFolder?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    type: string,
    updatedAt: string,
    status?: ProjectStatus | null,
    wizardDialogData?:  {
      __typename: "WizardDialogData",
      step_1?: string | null,
      step_2?: string | null,
    } | null,
    imagesContstructingThumbnailIds?: Array< string | null > | null,
    username: string,
    createdAt: string,
    folderProjectsId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateFolderSubscriptionVariables = {
  filter?: ModelSubscriptionFolderFilterInput | null,
  owner?: string | null,
};

export type OnCreateFolderSubscription = {
  onCreateFolder?:  {
    __typename: "Folder",
    id: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name?: string | null,
        selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
        type: string,
        updatedAt: string,
        status?: ProjectStatus | null,
        imagesContstructingThumbnailIds?: Array< string | null > | null,
        username: string,
        createdAt: string,
        folderProjectsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    childFolders?:  {
      __typename: "ModelFolderConnection",
      items:  Array< {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    parent?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    folderChildFoldersId?: string | null,
    folderParentId?: string | null,
  } | null,
};

export type OnUpdateFolderSubscriptionVariables = {
  filter?: ModelSubscriptionFolderFilterInput | null,
  owner?: string | null,
};

export type OnUpdateFolderSubscription = {
  onUpdateFolder?:  {
    __typename: "Folder",
    id: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name?: string | null,
        selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
        type: string,
        updatedAt: string,
        status?: ProjectStatus | null,
        imagesContstructingThumbnailIds?: Array< string | null > | null,
        username: string,
        createdAt: string,
        folderProjectsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    childFolders?:  {
      __typename: "ModelFolderConnection",
      items:  Array< {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    parent?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    folderChildFoldersId?: string | null,
    folderParentId?: string | null,
  } | null,
};

export type OnDeleteFolderSubscriptionVariables = {
  filter?: ModelSubscriptionFolderFilterInput | null,
  owner?: string | null,
};

export type OnDeleteFolderSubscription = {
  onDeleteFolder?:  {
    __typename: "Folder",
    id: string,
    name: string,
    projects?:  {
      __typename: "ModelProjectConnection",
      items:  Array< {
        __typename: "Project",
        id: string,
        name?: string | null,
        selectedValuesOfUserBriefBlocks?: Array< string | null > | null,
        type: string,
        updatedAt: string,
        status?: ProjectStatus | null,
        imagesContstructingThumbnailIds?: Array< string | null > | null,
        username: string,
        createdAt: string,
        folderProjectsId?: string | null,
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    childFolders?:  {
      __typename: "ModelFolderConnection",
      items:  Array< {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    parent?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    owner?: string | null,
    createdAt: string,
    updatedAt: string,
    folderChildFoldersId?: string | null,
    folderParentId?: string | null,
  } | null,
};

export type OnCreateSpaceSubscriptionVariables = {
  filter?: ModelSubscriptionSpaceFilterInput | null,
  owner?: string | null,
};

export type OnCreateSpaceSubscription = {
  onCreateSpace?:  {
    __typename: "Space",
    id: string,
    name: string,
    rootFolder?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    spaceRootFolderId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateSpaceSubscriptionVariables = {
  filter?: ModelSubscriptionSpaceFilterInput | null,
  owner?: string | null,
};

export type OnUpdateSpaceSubscription = {
  onUpdateSpace?:  {
    __typename: "Space",
    id: string,
    name: string,
    rootFolder?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    spaceRootFolderId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteSpaceSubscriptionVariables = {
  filter?: ModelSubscriptionSpaceFilterInput | null,
  owner?: string | null,
};

export type OnDeleteSpaceSubscription = {
  onDeleteSpace?:  {
    __typename: "Space",
    id: string,
    name: string,
    rootFolder?:  {
      __typename: "Folder",
      id: string,
      name: string,
      projects?:  {
        __typename: "ModelProjectConnection",
        nextToken?: string | null,
      } | null,
      childFolders?:  {
        __typename: "ModelFolderConnection",
        nextToken?: string | null,
      } | null,
      parent?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      owner?: string | null,
      createdAt: string,
      updatedAt: string,
      folderChildFoldersId?: string | null,
      folderParentId?: string | null,
    } | null,
    username: string,
    createdAt: string,
    updatedAt: string,
    spaceRootFolderId?: string | null,
    owner?: string | null,
  } | null,
};

export type OnCreateActiveSpaceSubscriptionVariables = {
  filter?: ModelSubscriptionActiveSpaceFilterInput | null,
};

export type OnCreateActiveSpaceSubscription = {
  onCreateActiveSpace?:  {
    __typename: "ActiveSpace",
    space?:  {
      __typename: "Space",
      id: string,
      name: string,
      rootFolder?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      spaceRootFolderId?: string | null,
      owner?: string | null,
    } | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    activeSpaceSpaceId?: string | null,
  } | null,
};

export type OnUpdateActiveSpaceSubscriptionVariables = {
  filter?: ModelSubscriptionActiveSpaceFilterInput | null,
};

export type OnUpdateActiveSpaceSubscription = {
  onUpdateActiveSpace?:  {
    __typename: "ActiveSpace",
    space?:  {
      __typename: "Space",
      id: string,
      name: string,
      rootFolder?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      spaceRootFolderId?: string | null,
      owner?: string | null,
    } | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    activeSpaceSpaceId?: string | null,
  } | null,
};

export type OnDeleteActiveSpaceSubscriptionVariables = {
  filter?: ModelSubscriptionActiveSpaceFilterInput | null,
};

export type OnDeleteActiveSpaceSubscription = {
  onDeleteActiveSpace?:  {
    __typename: "ActiveSpace",
    space?:  {
      __typename: "Space",
      id: string,
      name: string,
      rootFolder?:  {
        __typename: "Folder",
        id: string,
        name: string,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        folderChildFoldersId?: string | null,
        folderParentId?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      spaceRootFolderId?: string | null,
      owner?: string | null,
    } | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    activeSpaceSpaceId?: string | null,
  } | null,
};

export type OnCreateActiveProductSubscriptionVariables = {
  filter?: ModelSubscriptionActiveProductFilterInput | null,
};

export type OnCreateActiveProductSubscription = {
  onCreateActiveProduct?:  {
    __typename: "ActiveProduct",
    product?:  {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    activeProductProductId?: string | null,
  } | null,
};

export type OnUpdateActiveProductSubscriptionVariables = {
  filter?: ModelSubscriptionActiveProductFilterInput | null,
};

export type OnUpdateActiveProductSubscription = {
  onUpdateActiveProduct?:  {
    __typename: "ActiveProduct",
    product?:  {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    activeProductProductId?: string | null,
  } | null,
};

export type OnDeleteActiveProductSubscriptionVariables = {
  filter?: ModelSubscriptionActiveProductFilterInput | null,
};

export type OnDeleteActiveProductSubscription = {
  onDeleteActiveProduct?:  {
    __typename: "ActiveProduct",
    product?:  {
      __typename: "Product",
      id: string,
      path: string,
      name: string,
      status: ProductStatus,
      productType?: ProductType | null,
      thumbnailPath?: string | null,
      supportedViewType?: Array< ViewType | null > | null,
      industryType?: string | null,
      productFamily?: string | null,
      size?:  {
        __typename: "ProductSize",
        width?: number | null,
        height?: number | null,
        depth?: number | null,
      } | null,
      assets?:  Array< {
        __typename: "ProductAsset",
        url?: string | null,
      } | null > | null,
      dataForInvoice?:  {
        __typename: "ProductDataForInvoice",
        customProductComplexityRank?: number | null,
        priceId?: string | null,
        immersiveProductId?: string | null,
        priceValue?: number | null,
        priceCurrency?: string | null,
      } | null,
      username: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
    activeProductProductId?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    onboarding:  {
      __typename: "OnboardingType",
      formSteps?:  Array< {
        __typename: "FormSteps",
        id?: number | null,
        data?: string | null,
        isSubmited?: boolean | null,
      } | null > | null,
      uiSteps?:  Array< {
        __typename: "UISteps",
        id?: number | null,
        completedStepTypes?: Array< UIStepType | null > | null,
        isSubmited?: boolean | null,
      } | null > | null,
    },
    roles?:  Array< {
      __typename: "UserAttribute",
      id?: number | null,
      value?: string | null,
    } | null > | null,
    goals?:  Array< {
      __typename: "UserAttribute",
      id?: number | null,
      value?: string | null,
    } | null > | null,
    stripeId?: string | null,
    limitation?:  {
      __typename: "LimitationType",
      generatedStoriesInLimitPeriod?: number | null,
      createdProjectsInLimitPeriod?: number | null,
      createdCustomProductsInLimitPeriod?: number | null,
      createdStandardProductsInLimitPeriod?: number | null,
      generatedReimagines?: number | null,
      downloadedImages?: number | null,
      downloadedVideos?: number | null,
      generatedPacks?: number | null,
      subscriptionStartDate?: string | null,
      limitResetDate?: string | null,
      projectLimit?: number | null,
      storiesLimit?: number | null,
      storiesLimitPeriod?: string | null,
      customProducts?: number | null,
      standardProducts?: number | null,
      images?: number | null,
      videos?: number | null,
      packsLimit?: number | null,
    } | null,
    seenSceneIds?: Array< string | null > | null,
    isSubscriptionCanceled?: boolean | null,
    isReimagineClicked?: boolean | null,
    trialSubscriptionStatus?: TrialSubscriptionStatus | null,
    location?:  {
      __typename: "Location",
      country?: string | null,
      countryName?: string | null,
      countryCode?: string | null,
      countryCodeISO3?: string | null,
      currency?: string | null,
      currencyName?: string | null,
      languages?: string | null,
    } | null,
    paymentMethodStatus?: PaymentMethodStatus | null,
    seenGeneratedPackIds?: Array< string | null > | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    onboarding:  {
      __typename: "OnboardingType",
      formSteps?:  Array< {
        __typename: "FormSteps",
        id?: number | null,
        data?: string | null,
        isSubmited?: boolean | null,
      } | null > | null,
      uiSteps?:  Array< {
        __typename: "UISteps",
        id?: number | null,
        completedStepTypes?: Array< UIStepType | null > | null,
        isSubmited?: boolean | null,
      } | null > | null,
    },
    roles?:  Array< {
      __typename: "UserAttribute",
      id?: number | null,
      value?: string | null,
    } | null > | null,
    goals?:  Array< {
      __typename: "UserAttribute",
      id?: number | null,
      value?: string | null,
    } | null > | null,
    stripeId?: string | null,
    limitation?:  {
      __typename: "LimitationType",
      generatedStoriesInLimitPeriod?: number | null,
      createdProjectsInLimitPeriod?: number | null,
      createdCustomProductsInLimitPeriod?: number | null,
      createdStandardProductsInLimitPeriod?: number | null,
      generatedReimagines?: number | null,
      downloadedImages?: number | null,
      downloadedVideos?: number | null,
      generatedPacks?: number | null,
      subscriptionStartDate?: string | null,
      limitResetDate?: string | null,
      projectLimit?: number | null,
      storiesLimit?: number | null,
      storiesLimitPeriod?: string | null,
      customProducts?: number | null,
      standardProducts?: number | null,
      images?: number | null,
      videos?: number | null,
      packsLimit?: number | null,
    } | null,
    seenSceneIds?: Array< string | null > | null,
    isSubscriptionCanceled?: boolean | null,
    isReimagineClicked?: boolean | null,
    trialSubscriptionStatus?: TrialSubscriptionStatus | null,
    location?:  {
      __typename: "Location",
      country?: string | null,
      countryName?: string | null,
      countryCode?: string | null,
      countryCodeISO3?: string | null,
      currency?: string | null,
      currencyName?: string | null,
      languages?: string | null,
    } | null,
    paymentMethodStatus?: PaymentMethodStatus | null,
    seenGeneratedPackIds?: Array< string | null > | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  filter?: ModelSubscriptionUserFilterInput | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    onboarding:  {
      __typename: "OnboardingType",
      formSteps?:  Array< {
        __typename: "FormSteps",
        id?: number | null,
        data?: string | null,
        isSubmited?: boolean | null,
      } | null > | null,
      uiSteps?:  Array< {
        __typename: "UISteps",
        id?: number | null,
        completedStepTypes?: Array< UIStepType | null > | null,
        isSubmited?: boolean | null,
      } | null > | null,
    },
    roles?:  Array< {
      __typename: "UserAttribute",
      id?: number | null,
      value?: string | null,
    } | null > | null,
    goals?:  Array< {
      __typename: "UserAttribute",
      id?: number | null,
      value?: string | null,
    } | null > | null,
    stripeId?: string | null,
    limitation?:  {
      __typename: "LimitationType",
      generatedStoriesInLimitPeriod?: number | null,
      createdProjectsInLimitPeriod?: number | null,
      createdCustomProductsInLimitPeriod?: number | null,
      createdStandardProductsInLimitPeriod?: number | null,
      generatedReimagines?: number | null,
      downloadedImages?: number | null,
      downloadedVideos?: number | null,
      generatedPacks?: number | null,
      subscriptionStartDate?: string | null,
      limitResetDate?: string | null,
      projectLimit?: number | null,
      storiesLimit?: number | null,
      storiesLimitPeriod?: string | null,
      customProducts?: number | null,
      standardProducts?: number | null,
      images?: number | null,
      videos?: number | null,
      packsLimit?: number | null,
    } | null,
    seenSceneIds?: Array< string | null > | null,
    isSubscriptionCanceled?: boolean | null,
    isReimagineClicked?: boolean | null,
    trialSubscriptionStatus?: TrialSubscriptionStatus | null,
    location?:  {
      __typename: "Location",
      country?: string | null,
      countryName?: string | null,
      countryCode?: string | null,
      countryCodeISO3?: string | null,
      currency?: string | null,
      currencyName?: string | null,
      languages?: string | null,
    } | null,
    paymentMethodStatus?: PaymentMethodStatus | null,
    seenGeneratedPackIds?: Array< string | null > | null,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePromptCatalogSubscriptionVariables = {
  filter?: ModelSubscriptionPromptCatalogFilterInput | null,
};

export type OnCreatePromptCatalogSubscription = {
  onCreatePromptCatalog?:  {
    __typename: "PromptCatalog",
    id: string,
    colors?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    elements?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    descriptions?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePromptCatalogSubscriptionVariables = {
  filter?: ModelSubscriptionPromptCatalogFilterInput | null,
};

export type OnUpdatePromptCatalogSubscription = {
  onUpdatePromptCatalog?:  {
    __typename: "PromptCatalog",
    id: string,
    colors?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    elements?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    descriptions?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePromptCatalogSubscriptionVariables = {
  filter?: ModelSubscriptionPromptCatalogFilterInput | null,
};

export type OnDeletePromptCatalogSubscription = {
  onDeletePromptCatalog?:  {
    __typename: "PromptCatalog",
    id: string,
    colors?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    elements?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    descriptions?:  Array< {
      __typename: "Category",
      name: string,
      values?: Array< string | null > | null,
      subCategories?:  Array< {
        __typename: "Category",
        name: string,
        values?: Array< string | null > | null,
      } | null > | null,
    } | null > | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreatePromptHistorySubscriptionVariables = {
  filter?: ModelSubscriptionPromptHistoryFilterInput | null,
  owner?: string | null,
};

export type OnCreatePromptHistorySubscription = {
  onCreatePromptHistory?:  {
    __typename: "PromptHistory",
    description?: string | null,
    score?: Array< number | null > | null,
    username: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePromptHistorySubscriptionVariables = {
  filter?: ModelSubscriptionPromptHistoryFilterInput | null,
  owner?: string | null,
};

export type OnUpdatePromptHistorySubscription = {
  onUpdatePromptHistory?:  {
    __typename: "PromptHistory",
    description?: string | null,
    score?: Array< number | null > | null,
    username: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePromptHistorySubscriptionVariables = {
  filter?: ModelSubscriptionPromptHistoryFilterInput | null,
  owner?: string | null,
};

export type OnDeletePromptHistorySubscription = {
  onDeletePromptHistory?:  {
    __typename: "PromptHistory",
    description?: string | null,
    score?: Array< number | null > | null,
    username: string,
    id: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateServiceSubscriptionVariables = {
  filter?: ModelSubscriptionServiceFilterInput | null,
};

export type OnCreateServiceSubscription = {
  onCreateService?:  {
    __typename: "Service",
    id: string,
    name: string,
    poorAvailabilityDetectedTime?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateServiceSubscriptionVariables = {
  filter?: ModelSubscriptionServiceFilterInput | null,
};

export type OnUpdateServiceSubscription = {
  onUpdateService?:  {
    __typename: "Service",
    id: string,
    name: string,
    poorAvailabilityDetectedTime?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteServiceSubscriptionVariables = {
  filter?: ModelSubscriptionServiceFilterInput | null,
};

export type OnDeleteServiceSubscription = {
  onDeleteService?:  {
    __typename: "Service",
    id: string,
    name: string,
    poorAvailabilityDetectedTime?: string | null,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateSceneBundledScenesSubscriptionVariables = {
  filter?: ModelSubscriptionSceneBundledScenesFilterInput | null,
};

export type OnCreateSceneBundledScenesSubscription = {
  onCreateSceneBundledScenes?:  {
    __typename: "SceneBundledScenes",
    id: string,
    sceneId: string,
    bundledScenesId: string,
    scene:  {
      __typename: "Scene",
      id: string,
      path: string,
      name: string,
      brief:  {
        __typename: "Brief",
        description?: string | null,
        blocks?: Array< BlockType | null > | null,
      },
      status: SceneStatus,
      type?: SceneType | null,
      format?: SceneFormat | null,
      sceneDescription?: string | null,
      includeInPromptCatalog?: boolean | null,
      viewsSupported?: Array< ViewType | null > | null,
      thumbnailPath?: string | null,
      isAnimatable?: boolean | null,
      selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
      optionsPerBlock?: Array< Array< string | null > | null > | null,
      themes?:  Array< {
        __typename: "SceneTheme",
        main?: string | null,
        sub?: string | null,
      } | null > | null,
      productSupportedSize?: number | null,
      hasBlurryBG?: boolean | null,
      bundledScenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    bundledScenes:  {
      __typename: "BundledScenes",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      scenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      packs?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      mediaType?: MediaType | null,
      status?: BundledScenesStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateSceneBundledScenesSubscriptionVariables = {
  filter?: ModelSubscriptionSceneBundledScenesFilterInput | null,
};

export type OnUpdateSceneBundledScenesSubscription = {
  onUpdateSceneBundledScenes?:  {
    __typename: "SceneBundledScenes",
    id: string,
    sceneId: string,
    bundledScenesId: string,
    scene:  {
      __typename: "Scene",
      id: string,
      path: string,
      name: string,
      brief:  {
        __typename: "Brief",
        description?: string | null,
        blocks?: Array< BlockType | null > | null,
      },
      status: SceneStatus,
      type?: SceneType | null,
      format?: SceneFormat | null,
      sceneDescription?: string | null,
      includeInPromptCatalog?: boolean | null,
      viewsSupported?: Array< ViewType | null > | null,
      thumbnailPath?: string | null,
      isAnimatable?: boolean | null,
      selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
      optionsPerBlock?: Array< Array< string | null > | null > | null,
      themes?:  Array< {
        __typename: "SceneTheme",
        main?: string | null,
        sub?: string | null,
      } | null > | null,
      productSupportedSize?: number | null,
      hasBlurryBG?: boolean | null,
      bundledScenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    bundledScenes:  {
      __typename: "BundledScenes",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      scenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      packs?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      mediaType?: MediaType | null,
      status?: BundledScenesStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteSceneBundledScenesSubscriptionVariables = {
  filter?: ModelSubscriptionSceneBundledScenesFilterInput | null,
};

export type OnDeleteSceneBundledScenesSubscription = {
  onDeleteSceneBundledScenes?:  {
    __typename: "SceneBundledScenes",
    id: string,
    sceneId: string,
    bundledScenesId: string,
    scene:  {
      __typename: "Scene",
      id: string,
      path: string,
      name: string,
      brief:  {
        __typename: "Brief",
        description?: string | null,
        blocks?: Array< BlockType | null > | null,
      },
      status: SceneStatus,
      type?: SceneType | null,
      format?: SceneFormat | null,
      sceneDescription?: string | null,
      includeInPromptCatalog?: boolean | null,
      viewsSupported?: Array< ViewType | null > | null,
      thumbnailPath?: string | null,
      isAnimatable?: boolean | null,
      selectedValuesOfBlocks?: Array< Array< string | null > | null > | null,
      optionsPerBlock?: Array< Array< string | null > | null > | null,
      themes?:  Array< {
        __typename: "SceneTheme",
        main?: string | null,
        sub?: string | null,
      } | null > | null,
      productSupportedSize?: number | null,
      hasBlurryBG?: boolean | null,
      bundledScenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    bundledScenes:  {
      __typename: "BundledScenes",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      scenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      packs?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      mediaType?: MediaType | null,
      status?: BundledScenesStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreatePackBundledScenesSubscriptionVariables = {
  filter?: ModelSubscriptionPackBundledScenesFilterInput | null,
};

export type OnCreatePackBundledScenesSubscription = {
  onCreatePackBundledScenes?:  {
    __typename: "PackBundledScenes",
    id: string,
    bundledScenesId: string,
    packId: string,
    bundledScenes:  {
      __typename: "BundledScenes",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      scenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      packs?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      mediaType?: MediaType | null,
      status?: BundledScenesStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    pack:  {
      __typename: "Pack",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      level?: number | null,
      bundledScenes?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      tags?: Array< PackTag | null > | null,
      status?: PackStatus | null,
      order?: number | null,
      exampleImages?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdatePackBundledScenesSubscriptionVariables = {
  filter?: ModelSubscriptionPackBundledScenesFilterInput | null,
};

export type OnUpdatePackBundledScenesSubscription = {
  onUpdatePackBundledScenes?:  {
    __typename: "PackBundledScenes",
    id: string,
    bundledScenesId: string,
    packId: string,
    bundledScenes:  {
      __typename: "BundledScenes",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      scenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      packs?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      mediaType?: MediaType | null,
      status?: BundledScenesStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    pack:  {
      __typename: "Pack",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      level?: number | null,
      bundledScenes?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      tags?: Array< PackTag | null > | null,
      status?: PackStatus | null,
      order?: number | null,
      exampleImages?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeletePackBundledScenesSubscriptionVariables = {
  filter?: ModelSubscriptionPackBundledScenesFilterInput | null,
};

export type OnDeletePackBundledScenesSubscription = {
  onDeletePackBundledScenes?:  {
    __typename: "PackBundledScenes",
    id: string,
    bundledScenesId: string,
    packId: string,
    bundledScenes:  {
      __typename: "BundledScenes",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      scenes?:  {
        __typename: "ModelSceneBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      packs?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      mediaType?: MediaType | null,
      status?: BundledScenesStatus | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    pack:  {
      __typename: "Pack",
      id: string,
      name?: string | null,
      description?: string | null,
      thumbnailPath?: string | null,
      level?: number | null,
      bundledScenes?:  {
        __typename: "ModelPackBundledScenesConnection",
        nextToken?: string | null,
      } | null,
      tags?: Array< PackTag | null > | null,
      status?: PackStatus | null,
      order?: number | null,
      exampleImages?: Array< string | null > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};
