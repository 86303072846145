import { BlockType } from '../../src/API';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export interface ProductsToChoose {
    id: string;
    name: string;
    description: string;
    footprint: string;
    icon: IconDefinition;
    iconBgColor: string;
    iconColor: string;
    popular: boolean;
}

export const defaultUserBrief = {
    blocks: [BlockType.GENRATED_VIEW, BlockType.MEDIA_MEDIUM, BlockType.PRODUCT],
    description: '{block} {block} for {block}',
};

export enum DashboardView {
    Home = 'home',
    Projects = 'projects',
    Products = 'products',
    Media = 'media'
}

export enum DownloadQueueItemType {
    Video,
    RemoteVideo,
    Image,
}

export type DownloadQueueItem = {
    name: string;
    progress: number;
    isFinished: boolean;
    isError: boolean;
    type?: DownloadQueueItemType;
    thumbnail: string;
};

export enum ProductStatusTitle {
    DRAFT = 'Draft',
    IN_REVIEW = 'In Review',
    WAITING_FOR_PAYMENT = 'Payment Pending',
    PAYMENT_RECEIVED = 'In Progress',
    DESIGN_IN_PROGRESS = 'In Progress',
    AVAILABLE = 'Live',
    ARCHIVED = 'Archived',
    INACTIVE = 'Inactive',
}

export enum ProductTypeTitle {
    CUSTOM = 'Immersive Product',
    CUSTOM_2D = 'Standard Product',
    SAMPLE = 'Sample Product'
}

export enum PageType {
    ONBOARDING = 'onboarding',
    GUIDE = 'guide',
    SUBSCRIPTION_THANK_YOU = 'thank-you',
    SUBSCRIPTION_CANCEL = 'cancel',
    FEEDBACK = 'feedback',
    OPTIMIZE_THANK_YOU = 'thank-you-optimize',
}

export enum UserDataFormType {
    ONBOARDING = 'onboarding',
    OPTIMIZE = 'optimize',
}

export enum ViewLimitation {
    PROJECTS_HOME = 9,
}

export enum SessionStorageItemType {
    SIDEBAR_HIDDEN_STATE = 'sidebarHiddenState',
    PREV_URL_BEFORE_REDIRECTING_TO_OPTIMIZE_FORM = 'prevUrlBeforeRedirectingToOptimizeForm',
    WIZARD_SELECTED_ITEMS = 'wizardSelectedItems',
    CREATED_STANDARD_PRODUCTS_AMOUNT = 'createdStandardProductsAmount',
    ADD_IMMERSIVE_PRODUCT_FLOW = 'addImersiveProductFlow',
    ADD_IMMERSIVE_PRODUCT_FLOW_SCREEN = 'addImmersiveProductFlowScreen',
    SHOW_TRIAL_POPUP = 'showTrialPopup'
}

export enum FeedbackSourceType {
    NAVIGATION_BAR = 'Navigation bar',
    HELP_PANEL = 'Help panel',
}

export enum OnboardingFormStep {
    WELCOME = 1,
    PRODUCTS = 2,
    MY_PRODUCT = 3,
}

export enum OnboardingUIStep {
    NEW_PROJECT = 1,
    NEW_PRODUCT = 2,
}

export const lastOnboardingFormStep: OnboardingFormStep = OnboardingFormStep.WELCOME;
export const lastOnboardingUIStep: OnboardingUIStep = OnboardingUIStep.NEW_PROJECT;

export enum LimitReachedReasonType {
    CREATE_PROJECT = 'The limit of created projects has been reached',
    CREATE_PRODUCT = 'The limit of created products has been reached',
    DOWNLOAD_VIDEO = 'The limit of downloading video has been reached',
    DOWNLOAD_IMAGE = 'The limit of downloading image has been reached',
}

export enum PromptBoxLimits {
    PROMPT_BOX_MAX_CHARS_LENGTH = 120,
}

export enum AddProductType {
    STANDARD = 'standard',
    IMMERSIVE = 'immersive',
}

export enum ProductDetailsType {
    PRODUCT_TYPE = 'productType',
    INDUSTRY_TYPE = 'industryType',
    PRODUCT_HEIGHT = 'productHeight',
    PRODUCT_WIDTH = 'productWidth',
    PRODUCT_DEPTH = 'productDepth',
    PRODUCT_UNITS = 'productUnits',
}

export type ProductDetails = {
    productHeight: string;
    productWidth: string;
    productDepth: string;
    productUnits: string;
};

export interface AddProductFormComponentProps {
    setMissingRequirementInStep: (value: string) => void;
    setSelectedImageFile: (file: File) => void;
    removingBackground: boolean;
    imageWithoutBGUrl: string;
    setProductHeight: (height: number) => void;
    setProductWidth: (width: number) => void;
    setProductDepth: (depth: number) => void;
    productDetails: ProductDetails;
    setProductDetails: (productDetails: ProductDetails) => void;
    selectedFileUrl: string;
    setSelectedFileUrl: (url: string) => void;
    productActualDetails: {
        productHeight: number | null;
        productWidth: number | null;
        productDepth: number | null;
    };
    isErrorOccured: boolean;
    setIsErrorOccured: (value: boolean) => void;
    productIsAvailable: boolean;
}

export enum PromptBoxActions {
    PROMPT_BOX_CLICK = 'click',
    PROMPT_BOX_KEYDOWN = 'keydown',
    PROMPT_BOX_SURPRISE_BUTTON_CLICK = 'surprise',
}

export enum TokenGenerationType {
    CANNY = 'canny',
}

const CmToInch = 1 / 2.54;
export const Max2DProductSizeValueInCm = 25;
export const Max2DProductSizeValueInInches = Number((Max2DProductSizeValueInCm * CmToInch).toFixed());

export enum ProgressType {
    DOWNLOAD_PROGRESS = 'downloadProgress',
    ACCEPT_PROGRESS = 'acceptProgress',
}

export enum MediaMediumBlockValueType {
    PHOTO = '0',
    VIDEO = '1',
}

export enum GeneratedViewType {
    TOP = 'Top view',
    ALL_VIEWS = 'All views',
}

export enum GeneratedViewBlockValueType {
    TOP = '0',
    ALL_VIEWS = '1',
}

export const defaultProductSize = 0.25;
export const userFullNameMaxLength = 18;

export enum ProjectType {
    PROMPT = 'prompt',
    WIZARD = 'wizard',
}

export enum NumStoriesInGenerationType {
    PROMPT = 6,
    WIZARD = 12,
}

export const productNameMaxLength = 20;